// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  convertLength,
  formatNumber,
  roundNumber,
} from '../../../../../plugins/general';

// Types
import { Theme } from '../../../../../types/theme';

// Plugins
import { lastOperationalUnitData } from '../../../../../plugins/crusher';

// Components
import { Paragraph, ParagraphWeight } from '../../../../../layout/elements/Paragraph';
import {
  Title,
  TitleSize,
  TitleWeight,
} from '../../../../../layout/elements/Title';

// Store
import {
  getMeasurementUnit,
  LengthType,
  MeasurementType,
} from '../../../../../store/measurement.store';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// View
import {
  LinerWearContainer,
  LinerWearContent,
  ParagraphUnitContainer,
} from './LinerWearModule.style';
import { CrusherLinerWearModuleProps } from './LinerWearModule.types';

function LinerWearModule({
  className,
  decimalPlaces = 2,
  operationalData,
  testId,
}: CrusherLinerWearModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const userLengthUnit: LengthType = getMeasurementUnit(MeasurementType.length);

  // Format the linerWear.value to specified decimal places
  const roundedValue: number | null | undefined = roundNumber(
    convertLength(lastUnitData?.linerWear, userLengthUnit),
    decimalPlaces,
  );

  return (
    <LinerWearContainer data-testid={testId} className={className}>
      <Paragraph weight={ParagraphWeight.bold}>
        {t('view.crusher.linerWear')}
      </Paragraph>
      <LinerWearContent>
        <Title size={TitleSize.xxs} weight={TitleWeight.medium}>
          {formatNumber(roundedValue)}
        </Title>
        {
          userLengthUnit === LengthType.inch
            ? (
              <Title
                size={TitleSize.xxs}
                weight={TitleWeight.bold}
                color={theme.color.crusherDashboard.lineWear.unitTextColor}
              >
                {userLengthUnit}
              </Title>
            ) : (
              <ParagraphUnitContainer>
                <Paragraph
                  weight={ParagraphWeight.bold}
                  color={theme.color.crusherDashboard.lineWear.unitTextColor}
                >
                  {userLengthUnit}
                </Paragraph>
              </ParagraphUnitContainer>
            )
          }
      </LinerWearContent>
    </LinerWearContainer>
  );
}

export { LinerWearModule };

export enum SettingsOptions {
  profileSettings = 'profileSettings',
}

export interface SettingsModuleProps {
  className?: string;
  onItemSelected: (selectedItem: string) => void;
  selectedItem: string;
  testId?: string;
}

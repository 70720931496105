// Core
import styled from 'styled-components';

export const MainInfoContainer = styled.div`
  padding: 16px 0 20px 0;
`;

export const MainInformationTitle = styled.div`
  padding-bottom: 12px;
`;

export const CustomerInfoContainer = styled.div`
  padding-top: 16px;
`;

export const CustomerInformationTitle = styled.div`
  padding-bottom: 12px;
`;

export const SchemaDataContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;
`;

// Libraries
import create from 'zustand';
import { persist } from 'zustand/middleware';

export enum TemperatureType {
  c = '°C',
  f = '°F',
}

export enum PressureType {
  bar = 'bar',
  mpa = 'mpa',
  psi = 'psi',
}

export enum LengthType {
  inch = '"',
  mm = 'mm',
}

export enum MeasurementType {
  length = 'length',
  pressure = 'pressure',
  temperature = 'temperature',
}

export interface MeasurementStore {
  [MeasurementType.length]: LengthType;
  [MeasurementType.pressure]: PressureType;
  [MeasurementType.temperature]: TemperatureType;
}

export const useMeasurementStore = create(
  persist<MeasurementStore>(
    () => ({
      length: LengthType.mm,
      pressure: PressureType.mpa,
      temperature: TemperatureType.c,
    }),
    {
      name: 'measurement-store',
      getStorage: () => localStorage,
    },
  ),
);

export const getMeasurementUnit = <T extends MeasurementType>(
  measurementType: T,
): MeasurementStore[T] => useMeasurementStore.getState()[measurementType];

export const setMeasurementUnit = (
  value: LengthType | PressureType | TemperatureType,
  measurementType: MeasurementType,
): void => {
  useMeasurementStore.setState((state: MeasurementStore) => ({
    ...state,
    [measurementType]: value,
  }));
};

// Core
import styled from 'styled-components';

export const LinerWearContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
`;

export const LinerWearContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2px;
`;

export const ParagraphUnitContainer = styled.div`
  padding-bottom: 1.5px;
`;

// Core
import React, {
  useEffect,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { formatCrusherModel } from '../../../../../plugins/crusher';
import { capitalizeFirstLetter } from '../../../../../plugins/general';
import { RequestMethod, requestVantageApi } from '../../../../../plugins/request';

// Types
import {
  CrusherDataSchema,
  CrusherModel,
  CrusherPLCTagSchema,
} from '../../../../../types/crusher';

// Components
import { CircleInfo } from '../../../../../layout/icons';
import { Drawer } from '../../../../../layout/elements/Drawer';
import { Input } from '../../../../../layout/elements/Input';
import { Paragraph, ParagraphWeight } from '../../../../../layout/elements/Paragraph';
import { Select, SelectSizeType } from '../../../../../layout/elements/Select';
import { showToast, ToastType } from '../../../../../layout/elements/Toast';
import { Tooltip, TooltipPlacement } from '../../../../../layout/elements/Tooltip';

// View
import { UpdateInventoryProps } from './Update.types';
import {
  CustomerInfoActionsContainer,
  CustomerInfoContainer,
  MainInfoActionsContainer,
  MainInfoContainer,
} from './Update.style';

function UpdateInventory({
  className,
  customer,
  crusherSchemas,
  equipmentUnit,
  isDrawerOpen,
  onCloseDrawer,
  onUpdateData,
  testId,
}: UpdateInventoryProps): JSX.Element {
  const { t } = useTranslation();

  /* ***********************************************************************************************
  *************************************** LOCAL STATES *********************************************
  *********************************************************************************************** */

  interface State {
    crusherModel?: CrusherModel | null
    dataSchema?: CrusherDataSchema | null;
    isLoading: number | true | null;
    plcTagSchema?: CrusherPLCTagSchema | null;
    valueCustomerUnitName?: string | number | null;
    valueSerialNumber?: string | number | null;
  }

  const initialState: State = {
    crusherModel: equipmentUnit?.model,
    dataSchema: equipmentUnit?.dataSchema,
    isLoading: null,
    plcTagSchema: equipmentUnit?.plcTagSchema,
    valueCustomerUnitName: equipmentUnit?.customerUnitName,
    valueSerialNumber: equipmentUnit?.serialNumber,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  *********************************************** METHODS ******************************************
  *********************************************************************************************** */

  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: 100,
    }));
  };

  /* Request */
  const onUpdate = async (): Promise<void> => {
    startDrawerLoading();

    try {
      await requestVantageApi.equipmentUnit({
        method: RequestMethod.PUT,
        path: `/crusher/inventory/${equipmentUnit?.id}`,
        params: { idCustomer: customer?.id },
        body: {
          crusherModel: state.crusherModel,
          customerUnitName: state.valueCustomerUnitName,
          dataSchema: state.dataSchema,
          plcTagSchema: state.plcTagSchema,
          serialNumber: state.valueSerialNumber,
        },
      });

      if (equipmentUnit && equipmentUnit.equipmentCategory) {
        showToast({
          text: t('view.inventory.update.updateEquipment', {
            category: capitalizeFirstLetter(equipmentUnit.equipmentCategory),
          }),
          type: ToastType.success,
        });
      }
      setState((prevState: State): State => ({
        ...prevState,
        isLoading: null,
      }));

      stopDrawerLoading();

      setTimeout((): void => {
        onCloseDrawer();
        onUpdateData();
      }, 300);
    } catch (error: unknown) {
      setState((prevState: State): State => ({
        ...prevState,
        isLoading: null,
      }));
    }
  };

  // Check if the update button should be disabled
  const isUpdateButtonDisabled = (): boolean => (
    state.valueSerialNumber === initialState.valueSerialNumber
      && state.valueCustomerUnitName === initialState.valueCustomerUnitName
      && state.crusherModel === initialState.crusherModel
      && state.dataSchema === initialState.dataSchema
      && state.plcTagSchema === initialState.plcTagSchema
  );

  /* ***********************************************************************************************
  ************************************ COMPONENT HANDLING ******************************************
  *********************************************************************************************** */

  useEffect((): void => {
    setState({
      crusherModel: equipmentUnit?.model,
      dataSchema: equipmentUnit?.dataSchema,
      isLoading: null,
      plcTagSchema: equipmentUnit?.plcTagSchema,
      valueCustomerUnitName: equipmentUnit?.customerUnitName,
      valueSerialNumber: equipmentUnit?.serialNumber,
    });
  }, [equipmentUnit]);

  return (
    <Drawer
      testId={testId}
      className={className}
      title={`${t('view.inventory.update.title')}`}
      onClose={onCloseDrawer}
      open={isDrawerOpen}
      primaryAction={{
        children: `${t('common.edit')}`,
        onClick: onUpdate,
        disabled: isUpdateButtonDisabled(),
        loading: state.isLoading,
        loadingText: `${t('common.editing')}`,
      }}
      secondaryAction={{ children: `${t('common.cancel')}`, onClick: onCloseDrawer }}
    >
      <MainInfoContainer>
        <Paragraph weight={ParagraphWeight.bold}>
          {`${t('common.mainInfo')}`}
        </Paragraph>
      </MainInfoContainer>

      <MainInfoActionsContainer>
        {/* Serial Number */}
        <Input
          testId="serial-number-input-update-inventory"
          onChange={(value?: string | number | null): void => {
            setState((prevState: State): State => ({
              ...prevState,
              valueSerialNumber: value,
            }));
          }}
          label={`${t('common.serialNumber')}`}
          placeholder={`${t('common.serialNumber')}`}
          value={state.valueSerialNumber}
          maxLength={35}
        />
        {/* Crusher Model */}
        <Select
          testId="crusher-model-select-create-inventory"
          label={`${t('view.crusher.model')}`}
          placeholder={`${t('view.crusher.model')}`}
          items={Object.keys(crusherSchemas || {}).map((model) => ({
            id: model,
            text: formatCrusherModel(model as CrusherModel),
          }))}
          onChange={(value): void => {
            const selectedValue: string | null = typeof value === 'string'
              ? value
              : value[0] || null;
            setState((prevState: State) => ({
              ...prevState,
              crusherModel: selectedValue as CrusherModel,
            }));
          }}
          value={state.crusherModel || undefined}
          size={SelectSizeType.lg}
          allowClear={false}
        />
        {/* PLC Tag Schema */}
        <Select
          testId="plc-tag-schema-select-update-inventory"
          label="PLC Tag Schema"
          placeholder="PLC Tag Schema"
          items={
            Array.from(
              new Set(
                Object.values(crusherSchemas || {})
                  .flatMap((plcSchemas) => Object.keys(plcSchemas)),
              ),
            ).map((tag) => ({
              id: tag,
              text: tag,
            }))
          }
          onChange={(value): void => {
            const selectedValue: string | null = typeof value === 'string'
              ? value
              : value[0] || null;
            setState((prevState: State) => ({
              ...prevState,
              plcTagSchema: selectedValue as CrusherPLCTagSchema,
            }));
          }}
          value={state.plcTagSchema || undefined}
          size={SelectSizeType.lg}
          allowClear={false}
        />
        {/* Data Schema */}
        <Select
          testId="crusher-data-schema-select-update-inventory"
          label="Data Schema"
          placeholder="Data Schema"
          items={
            Array.from(
              new Set(
                Object.values(crusherSchemas || {})
                  .flatMap((plcTags) => Object.values(plcTags).flat()),
              ),
            ).map((dataSchema) => ({
              id: dataSchema as string,
              text: dataSchema as string,
            }))
          }
          onChange={(value): void => {
            const selectedValue: string | null = typeof value === 'string'
              ? value
              : value[0] || null;
            setState((prevState: State) => ({
              ...prevState,
              dataSchema: selectedValue as CrusherDataSchema,
            }));
          }}
          value={state.dataSchema || undefined}
          size={SelectSizeType.lg}
          allowClear={false}
        />
      </MainInfoActionsContainer>

      <CustomerInfoContainer>
        <Paragraph weight={ParagraphWeight.bold}>
          {`${t('view.inventory.customerInfo')}`}
        </Paragraph>
      </CustomerInfoContainer>

      <CustomerInfoActionsContainer>
        <Input
          testId="customer-part-number-input-update-inventory"
          onChange={(value?: string | number | null): void => {
            setState((prevState: State): State => ({
              ...prevState,
              valueCustomerUnitName: value,
            }));
          }}
          label={`${t('common.customerUnitName')}`}
          placeholder={`${t('common.customerUnitName')}`}
          value={state.valueCustomerUnitName}
          maxLength={65}
          suffix={(
            <Tooltip
              text={`${t('view.inventory.create.tooltip.customerUnitName')}`}
              placement={TooltipPlacement.left}
            >
              <CircleInfo />
            </Tooltip>
          )}
        />
      </CustomerInfoActionsContainer>
    </Drawer>
  );
}

export { UpdateInventory };

// Core
import React, {
  useCallback,
  useEffect,
} from 'react';

// Libraries
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import _ from 'lodash';

// Plugins
import { timestampToTimeAgo } from '../../../../../plugins/general';

// Types
import {
  CrusherDashboardChartRange,
  EquipmentUnitDataCrusher,
  TrendingType,
} from '../../../../../types/crusher';
import { EdgeDeviceOperationalStatus } from '../../../../../types/global';
import { Theme } from '../../../../../types/theme';

// Components
import { Loader, LoaderSize } from '../../../../../layout/elements/Loader';
import { ModalPanel } from '../../../../../layout/elements/ModalPanel';
import { NavigationTabs } from '../../../../../layout/elements/NavigationTabs';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';

// Trendings Chart
import { TrendingsHydraulicUnit } from './TrendingsHydraulicUnit';
import { TrendingsLubeUnit } from './TrendingsLubeUnit';
import { TrendingsMotorCurrent } from './TrendingsMotorCurrent';
import { TrendingsPID } from './TrendingsPID';
import { TrendingsRingBounce } from './TrendingsRingBounce';
import { TrendingsProps } from './Trendings.types';
import {
  ChartTrendingsTabContainer,
  LastUpdateTime,
  LineChartContainer,
  MotorCurrentTextContainer,
  TabsTrendingsContainer,
  TrendingsDetailsContainer,
  TrendingsLoadingContainer,
} from './Trendings.style';

function Trendings({
  className,
  fetchHistory,
  isLoading,
  onChangeChartRange,
  onChangeTrending,
  onCloseTrendings,
  operationalData,
  selectedChartRange,
  selectedTrending,
  showTrendings = false,
  testId,
}: TrendingsProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  // Constants
  const DEFAULT_CHART_RANGE: CrusherDashboardChartRange = CrusherDashboardChartRange.fiveMinutes;

  type ChartItemTab = {
    key: 'motorCurrent' | 'lubeUnit' | 'pid' | 'hydraulic' | 'ringBounceSensor';
    label: string;
  };

  type ChartRangeTab = {
    key: CrusherDashboardChartRange;
    label: string;
  };

  /* **********************************************************************************************
  ******************************************** METHODS ********************************************
  ********************************************************************************************** */

  // Get the last data from the history data
  const lastData: (
    EquipmentUnitDataCrusher | EdgeDeviceOperationalStatus
  ) | null = _.last(operationalData) || null;

  const getRenderChartComponent = useCallback((): JSX.Element | null => {
    let response: JSX.Element | null = null;

    if (!isLoading) {
      const renderChart: Record<TrendingType, JSX.Element> = {
        hydraulic: (
          <TrendingsHydraulicUnit
            testId="hydraulic-unit"
            operationalData={operationalData}
          />
        ),
        ringBounceSensor: (
          <TrendingsRingBounce
            testId="ring-bounce"
            operationalData={operationalData}
          />
        ),
        motorCurrent: (
          <TrendingsMotorCurrent
            testId="motor-current"
            operationalData={operationalData}
          />
        ),
        pid: (
          <TrendingsPID
            testId="pid"
            operationalData={operationalData}
          />
        ),
        lubeUnit: (
          <TrendingsLubeUnit
            testId="lube-unit"
            operationalData={operationalData}
          />
        ),
      };
      response = renderChart[selectedTrending];
    }

    return response;
  }, [
    operationalData,
    isLoading,
    selectedTrending,
  ]);

  const DEFAULT_TRANSLATE_PATH: string = 'view.crusher.trendings';

  // Trendings type data
  const itemsToTrendingsTab: ChartItemTab[] = [
    {
      key: 'motorCurrent', label: t(`${DEFAULT_TRANSLATE_PATH}.motorCurrent.title`),
    },
    {
      key: 'lubeUnit', label: t(`${DEFAULT_TRANSLATE_PATH}.lubeUnit.title`),
    },
    {
      key: 'pid', label: t(`${DEFAULT_TRANSLATE_PATH}.pid.title`),
    },
    {
      key: 'hydraulic', label: t(`${DEFAULT_TRANSLATE_PATH}.hydraulic.title`),
    },
    {
      key: 'ringBounceSensor', label: t(`${DEFAULT_TRANSLATE_PATH}.ringBounceSensor.title`),
    },
  ];

  // Trendings data to chart
  const itemsToChartTab: ChartRangeTab[] = [
    {
      key: CrusherDashboardChartRange.fiveMinutes,
      label: t('dates.minute.plural', { minutes: '5' }),
    },
    {
      key: CrusherDashboardChartRange.week,
      label: `${t('dates.day.plural', { days: '7' })}`,
    },
    {
      key: CrusherDashboardChartRange.month,
      label: `${t('dates.day.plural', { days: '30' })}`,
    },
  ];

  /* **********************************************************************************************
  ************************************** COMPONENT HANDLING ***************************************
  ********************************************************************************************** */

  useEffect((): void => {
    if (selectedChartRange === null) {
      fetchHistory(DEFAULT_CHART_RANGE);
    }
  }, [
    DEFAULT_CHART_RANGE,
    fetchHistory,
    selectedChartRange,
  ]);

  return (
    <ModalPanel
      testId={testId}
      className={className}
      onClose={onCloseTrendings}
      showModal={showTrendings}
      title={t('view.crusher.trendings.title')}
    >
      <div>
        <TabsTrendingsContainer>
          <NavigationTabs
            items={itemsToTrendingsTab}
            onClick={onChangeTrending}
            selected={selectedTrending}
          />
        </TabsTrendingsContainer>
        <TrendingsDetailsContainer>
          <MotorCurrentTextContainer>
            <Paragraph
              size={ParagraphSize.lg}
              weight={ParagraphWeight.bold}
              className="motor-current-paragraph"
            >
              {t(`${DEFAULT_TRANSLATE_PATH}.${selectedTrending}.title`)}
            </Paragraph>
            <Paragraph
              size={ParagraphSize.sm}
              color={theme.color.crusherDashboard.trendings.lastUpdateTextColor}
              testId="last-update-item"
            >
              <Trans
                i18nKey="common.lastUpdate"
                values={{ lastUpdate: timestampToTimeAgo(lastData?.timestamp) }}
                components={{ lastUpdateTime: <LastUpdateTime /> }}
              />
            </Paragraph>
          </MotorCurrentTextContainer>
          <ChartTrendingsTabContainer>
            <NavigationTabs
              items={itemsToChartTab}
              onClick={onChangeChartRange}
              selected={selectedChartRange || DEFAULT_CHART_RANGE}
            />
          </ChartTrendingsTabContainer>
        </TrendingsDetailsContainer>
        <LineChartContainer>
          {
            isLoading ? (
              <TrendingsLoadingContainer>
                <Loader
                  size={LoaderSize.xl}
                  text={`${t('common.loader')}`}
                  testId="loader-with-text"
                />
              </TrendingsLoadingContainer>
            ) : getRenderChartComponent()
          }
        </LineChartContainer>
      </div>
    </ModalPanel>
  );
}

export { Trendings };

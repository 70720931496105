// Core
import React, {
  useEffect,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import {
  NavigateFunction,
  useNavigate,
  useParams,
} from 'react-router-dom';

// Store
import { getUser } from '../../store/session.store';

// Types
import { User, UserType } from '../../types/global';

// Components
import { AdminPanel } from '../../layout/AdminPanel';
import { Col } from '../../layout/elements/Col';
import { CustomerPanel } from '../../layout/CustomerPanel';
import { PageHeader } from '../../layout/elements/PageHeader';
import { Row } from '../../layout/elements/Row';

// Module
import { MeasurementModule } from './MeasurementModule';
import { SettingsModule, SettingsOptions } from './SettingsModule';

// View
import {
  PageHeaderContainer,
  PanelContainer,
  ProfileSettingsContainer,
  RegisteredTrademark,
} from './Settings.style';

function Settings(): JSX.Element | null {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const { section } = useParams();

  // Getter
  const user: User | null = getUser();

  /* ***********************************************************************************************
  **************************************** INITIAL STATE *******************************************
  *********************************************************************************************** */

  type State = {
    selectedItem: string;
  };

  const initialState: State = {
    selectedItem: SettingsOptions.profileSettings,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  ****************************************** METHODS ***********************************************
  *********************************************************************************************** */

  const formatDescription = (description: string): JSX.Element[] => description.split(
    '{{registered}}',
  ).map((part: string, index: number) => (
    <React.Fragment key={part}>
      {index > 0 && <RegisteredTrademark>®</RegisteredTrademark>}
      {part}
    </React.Fragment>
  ));

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  *********************************************************************************************** */

  useEffect((): void => {
    if (!section) {
      navigate('/settings/profile-settings');
    }
  }, [section, navigate]);

  // Redirects if user is not found
  if (!user) {
    navigate('/plant-management');
    return null;
  }

  /* ************** ADMIN ******************* */

  const adminContent: { [key: string]: JSX.Element | null } = {
    profileSettings: (
      <ProfileSettingsContainer>
        <PageHeaderContainer>
          <PageHeader
            title={t('view.settings.profileTitle')}
            description={t('view.settings.profileDescription')}
          />
        </PageHeaderContainer>
        <MeasurementModule />
      </ProfileSettingsContainer>
    ),
    default: (
      <PageHeaderContainer>
        <PageHeader
          title={t('common.settings')}
          description={formatDescription(t('view.settings.adminDescription'))}
        />
      </PageHeaderContainer>
    ),
  };

  /* ************** CUSTOMER ******************* */

  const customerContent: { [key: string]: JSX.Element | null } = {
    profileSettings: (
      <ProfileSettingsContainer>
        <PageHeaderContainer>
          <PageHeader
            title={t('view.settings.profileTitle')}
            description={t('view.settings.profileDescription')}
          />
        </PageHeaderContainer>
        <MeasurementModule />
      </ProfileSettingsContainer>
    ),
    default: (
      <PageHeaderContainer>
        <PageHeader
          title={t('common.settings')}
          description={formatDescription(t('view.settings.customerDescription'))}
        />
      </PageHeaderContainer>
    ),
  };

  const renderSelectedAdminContent = (): JSX.Element | null => (
    adminContent[state.selectedItem || SettingsOptions.profileSettings]
  );

  const renderSelectedCustomerContent = (): JSX.Element | null => (
    customerContent[state.selectedItem || SettingsOptions.profileSettings]
  );

  return user.type === UserType.admin
    ? (
      <AdminPanel>
        <Row>
          <SettingsModule
            selectedItem={state.selectedItem}
            onItemSelected={(selectedItem: string): void => {
              const formattedSelection: string = selectedItem.replace(/([a-z])([A-Z])/g, '$1-$2')
                .toLowerCase();
              navigate(`/settings/${formattedSelection}`);
              setState({ selectedItem });
            }}
          />
          <Col cols={20}>
            <PanelContainer>
              {renderSelectedAdminContent()}
            </PanelContainer>
          </Col>
        </Row>
      </AdminPanel>
    ) : (
      <CustomerPanel>
        <Row>
          <SettingsModule
            selectedItem={state.selectedItem}
            onItemSelected={(selectedItem: string): void => {
              const formattedSelection: string = selectedItem.replace(/([a-z])([A-Z])/g, '$1-$2')
                .toLowerCase();
              navigate(`/settings/${formattedSelection}`);
              setState({ selectedItem });
            }}
          />
          <Col cols={20}>
            <PanelContainer>
              {renderSelectedCustomerContent()}
            </PanelContainer>
          </Col>
        </Row>
      </CustomerPanel>
    );
}

export { Settings };

// Core
import React from 'react';

// Components
import { CameraFeed, LiveStatus } from '../../../../../components/CameraFeed';

// View
import { CameraContainer } from './CameraModule.style';
import { CrusherCameraModuleProps } from './CameraModule.types';

function CameraModule({
  centerCoordinates,
  className,
  pins,
  polygons,
  running,
  testId,
  title,
}: CrusherCameraModuleProps): JSX.Element {
  return (
    <CameraContainer
      data-testid={testId}
      className={className}
    >
      <CameraFeed
        title={title}
        live={running ? LiveStatus.on : LiveStatus.off}
        testId="camera-element"
        polygons={polygons}
        pins={pins}
        centerCoordinates={centerCoordinates}
      />
    </CameraContainer>
  );
}

export { CameraModule };

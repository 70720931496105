/* eslint-disable @typescript-eslint/indent */

// Core
import styled, { css } from 'styled-components';

// Components
import { StatusIndicatorStatus } from '../../../../../../layout/elements/StatusIndicator';

// Types
import { Theme } from '../../../../../../types/theme';

/** Reusable styles */
const borderColor = (
  { theme }: { theme: Theme },
) => theme.color.crusherDashboard.hydraulicUnitDrawer.borderColor;

const flexBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BowlAdjustmentContainer = styled.div`
  padding: 14px 20px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ExecuteCommandButtonContainer = styled.div`
  margin-top: auto;
`;

export const OverallStatusContainer = styled.div`
  width: 100%;
  padding: 19px 24px;
  ${flexBetween};
  border-right: 1px solid ${borderColor};
`;

export const TankTempContainer = styled.div`
  width: 100%;
  padding: 19px 24px;
  ${flexBetween};
`;

export const CurrentCssContainer = styled.div`
  ${flexBetween};
  border-radius: 4px;
  margin-top: 16px;
  padding: 24px 20px;
  border: 1px solid ${borderColor};
  height: 68px;
`;

export const CSSettingContainer = styled.div`
  padding: 14px 20px;
  // border-right: 1px solid ${borderColor}; // @TODO: Add this line back when add controls
  height: 100%;
`;

export const TargetValueContainer = styled.div`
  ${flexBetween};
  border-radius: 4px;
  margin: 8px 0 16px;
  padding: 14px 20px;
  border: 1px solid ${borderColor};
  height: 68px;
  gap: 8px;
`;

export const StatusBowlAdjustment = styled.div`
  width: 100%;
  ${flexBetween};
  border-radius: 4px;
  margin-top: 16px;
  padding: 24px 20px;
  border: 1px solid ${borderColor};
`;

export const ParamIncrementorContainer = styled.div`
  box-sizing: border-box;
  flex: 1;
`;

export const ClampSpeedometerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 29px 0 44px;
  border-right: 1px solid ${borderColor};
`;

export const TrampSpeedometerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 29px 0 44px;
`;

export const NumberTeethContainer = styled.div`
  padding: 19px 23px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${borderColor};
`;

export const OperationModeContainer = styled.div`
  padding: 19px 23px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  justify-content: center;
  align-items: center;
`;

export const FooterContainer = styled.div`
  width: 100%;
  ${flexBetween};
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 20px;
  background-color: ${({
    theme,
  }) => theme.color.crusherDashboard.hydraulicUnitDrawer.hydraulicFooterBackgroundColor};
`;

export const OverallContainer = styled.div`
  width: 100%;
  border: 1px solid ${borderColor};
  border-radius: 4px;
`;

export const PermissiveContainer = styled.div`
  padding-top: 26px;
  border-right: 1px solid ${borderColor};
`;

export const PermissiveTitleContainer = styled.div`
  padding: 10px 20px;
`;

export const BowlMovementContainer = styled.div`
  padding-top: 26px;
`;

export const BowlMovementTitleContainer = styled.div`
  padding: 11px 20px;
`;

export const PermissiveList = styled.ul`
  padding: 0 20px 32px 32px;
  margin: 0;
`;

export const PermissiveItem = styled.li<{ status: StatusIndicatorStatus }>`
  list-style-type: none;
  padding: 12px 20px 12px 14px;
  background-color: ${({ status, theme }) => (
    status === StatusIndicatorStatus.alarm
      ? theme.color.crusherDashboard.hydraulicUnitDrawer.descriptionListErrorBackgroundColor
      : 'transparent'
  )};
  border-radius: 4px;
`;

export const BorderBottom = styled.div`
  border-bottom: 1px solid ${borderColor};
`;

export const CSSValueContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2px;
`;

export const ParagraphUnitContainer = styled.div`
  padding-bottom: 1px;
`;

// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Types
import { Theme } from '../../../../../types/theme';

// Plugins
import { formatCrusherModel } from '../../../../../plugins/crusher';

// Media
import ImageCrusher from '../../../../../media/images/equipment/crusher.png';

// Components
import { Col } from '../../../../../layout/elements/Col';
import { Drawer } from '../../../../../layout/elements/Drawer';
import { Edit, TrashCan } from '../../../../../layout/icons';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../layout/elements/Row';

// View
import { DrawerOperation } from '../../Inventory.types';
import { ReadInventoryProps } from './Read.types';
import {
  CustomerInfoContainer,
  CustomerInformationTitle,
  MainInfoContainer,
  MainInformationTitle,
  SchemaDataContainer,
} from './Read.style';

function ReadInventory({
  className,
  customer,
  equipmentUnit,
  isDrawerOpen,
  onCloseDrawer,
  onRead,
  testId,
}: ReadInventoryProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const theme: Theme = useTheme();

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t(`common.equipmentCategory.${equipmentUnit.equipmentCategory}.singular`)}
      onClose={onCloseDrawer}
      open={isDrawerOpen}
      footer={false}
      preview={{
        previewImage: ImageCrusher, // @TODO: Load image dynamically from backend in future
        primaryPreviewAction: {
          onClick: () => onRead(DrawerOperation.delete, equipmentUnit.id),
          children: <TrashCan />,
        },
        secondaryPreviewAction: {
          onClick: () => onRead(DrawerOperation.update, equipmentUnit.id),
          children: <Edit />,
        },
      }}
    >
      {equipmentUnit && (
        <div>
          <MainInfoContainer>
            <MainInformationTitle>
              <Paragraph
                weight={ParagraphWeight.bold}
                color={theme.color.inventory.descriptionColor}
              >
                {`${t('common.mainInfo')}`}
              </Paragraph>
            </MainInformationTitle>
            <Row>
              {/* Serial Number */}
              <Col cols={12}>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.bold}
                  color={theme.color.inventory.fieldTitleColor}
                >
                  {`${t('common.serialNumber')}`}
                </Paragraph>
                <Paragraph color={theme.color.inventory.fieldTextColor}>
                  {equipmentUnit.serialNumber}
                </Paragraph>
              </Col>
              {/* Superior Part Number */}
              <Col cols={12}>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.bold}
                  color={theme.color.inventory.fieldTitleColor}
                >
                  {`${t('common.superiorPartNumber')}`}
                </Paragraph>
                <Paragraph
                  color={theme.color.inventory.fieldTextColor}
                >
                  {formatCrusherModel(equipmentUnit.model)}
                </Paragraph>
              </Col>

              <SchemaDataContainer>
                {/* PLC Tag Schema */}
                <Col cols={12}>
                  <Paragraph
                    size={ParagraphSize.sm}
                    weight={ParagraphWeight.bold}
                    color={theme.color.inventory.fieldTitleColor}
                  >
                    PLC Tag Schema
                  </Paragraph>
                  <Paragraph
                    color={theme.color.inventory.fieldTextColor}
                  >
                    {equipmentUnit.plcTagSchema}
                  </Paragraph>
                </Col>
                {/* Data Schema */}
                <Col cols={12}>
                  <Paragraph
                    size={ParagraphSize.sm}
                    weight={ParagraphWeight.bold}
                    color={theme.color.inventory.fieldTitleColor}
                  >
                    Data Schema
                  </Paragraph>
                  <Paragraph
                    color={theme.color.inventory.fieldTextColor}
                  >
                    {equipmentUnit.dataSchema}
                  </Paragraph>
                </Col>
              </SchemaDataContainer>

            </Row>
          </MainInfoContainer>

          <CustomerInfoContainer>
            <CustomerInformationTitle>
              <Paragraph
                weight={ParagraphWeight.bold}
                color={theme.color.inventory.descriptionColor}
              >
                {`${t('view.inventory.customerInfo')}`}
              </Paragraph>
            </CustomerInformationTitle>
            <Row>
              {/* Customer */}
              <Col cols={12}>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.bold}
                  color={theme.color.inventory.fieldTitleColor}
                >
                  {`${t('common.customer')}`}
                </Paragraph>
                <Paragraph color={theme.color.inventory.fieldTextColor}>{customer.name}</Paragraph>
              </Col>
              {/* Customer Unit Name */}
              <Col cols={12}>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.bold}
                  color={theme.color.inventory.fieldTitleColor}
                >
                  {`${t('common.customerUnitName')}`}
                </Paragraph>
                <Paragraph color={theme.color.inventory.fieldTextColor}>
                  {equipmentUnit.customerUnitName || formatCrusherModel(equipmentUnit.model)}
                </Paragraph>
              </Col>
            </Row>
          </CustomerInfoContainer>
        </div>
      )}
    </Drawer>
  );
}

export { ReadInventory };

// Libraries
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeFormat from 'dayjs/plugin/relativeTime';
import _ from 'lodash';

// Locale
import i18n from '../locale/i18n';

// Store
import {
  LengthType,
  PressureType,
  TemperatureType,
} from '../store/measurement.store';

dayjs.extend(localizedFormat);
dayjs.extend(relativeFormat);

export const DASHBOARD_NULLISH_TEXT: string = '--';

/** Convert a localized string to Number(float) format */
export const parseLocaleNumber = (stringNumber: string, locale: string) => {
  const numberFormat = new Intl.NumberFormat(locale);

  const parts = numberFormat.formatToParts(12345.6);
  const thousandSeparator = parts.find((part) => part.type === 'group')?.value;
  const decimalSeparator = parts.find((part) => part.type === 'decimal')?.value;

  const normalizedNumber = stringNumber
    .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
    .replace(new RegExp(`\\${decimalSeparator}`), '.');

  return parseFloat(normalizedNumber);
};

/** Convert a hexadecimal color to a rgba color with an alpha value */
export const hexToRgba = (hex: string, alpha: number) => {
  const parsedHex = hex.replace('#', '');

  // Parse the hex values for red, green, and blue
  const r = parseInt(parsedHex.substring(0, 2), 16);
  const g = parseInt(parsedHex.substring(2, 4), 16);
  const b = parseInt(parsedHex.substring(4, 6), 16);

  // Ensure the alpha value is between 0 and 1
  const clampedAlpha = Math.min(1, Math.max(0, alpha));

  return `rgba(${r}, ${g}, ${b}, ${clampedAlpha})`;
};

/** Rounds the value to a specified number of decimal places */
export const roundNumber = (
  value: number | null | undefined,
  decimalPlaces: number = 0,
): number | null => {
  let result: number | null;
  if (!_.isNil(value)) {
    result = parseFloat(value.toFixed(decimalPlaces));
  } else {
    result = null;
  }
  return result;
};

/** Format a Number to a default locale format - string */
export const formatNumber = (
  num: number | null | undefined,
  decimals: number | null = null,
): string => (
  !_.isNil(num)
    ? num.toLocaleString(
      i18n.language.replace('_', '-'),
      {
        style: 'decimal',
        minimumFractionDigits: decimals !== null ? decimals : undefined,
        maximumFractionDigits: decimals !== null ? decimals : undefined,
      },
    )
    : DASHBOARD_NULLISH_TEXT
);

/** Converts a timestamp to a localized date string in MMM DD, YYYY format */
export const timestampToDateStrText = (timestamp: number): string => {
  const dayObj = dayjs(timestamp);
  return dayObj.format('ll');
};

/** Converts a timestamp to a localized time string in HH:mm(am/pm) format */
export const timestampToTimeStr = (timestamp: number): string => {
  const dayObj = dayjs(timestamp);
  return dayObj.format('LT');
};

/** Converts a timestamp to a localized "[x] [unit] ago" format */
export const timestampToTimeAgo = (
  timestamp: number | null | undefined,
  nullishText: string = DASHBOARD_NULLISH_TEXT,
): string => {
  let result: string;

  if (!_.isNil(timestamp)) {
    result = dayjs(timestamp).fromNow();
  } else {
    result = nullishText;
  }

  return result;
};

/** Converts file to base64 string */
export const convertFileToBase64 = (file: File | Blob): Promise<string> => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  })
);

/** Get the percentage of a value between a minimum and maximum value */
export const currentValuePercentage = ({
  value,
  minValue,
  maxValue,
}: {
  value?: number | null;
  minValue?: number | null;
  maxValue?: number | null;
}): number => {
  if (_.isNil(value) || _.isNil(minValue) || _.isNil(maxValue)) {
    throw new Error('Error: [value], [minValue], and [maxValue] must be valid numbers');
  }

  if (minValue === maxValue) {
    throw new Error('Error: Invalid params, [maxValue] and [minValue] cannot be equal');
  }

  return ((value - minValue) / (maxValue - minValue)) * 100;
};

/** Get the browser window dimensions */
export const getWindowDimensions = (): { width: number, height: number } => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/** Check the required environment variables */
export const validateEnvironmentVariables = (): void => {
  const requiredEnvVars: string[] = [
    'REACT_APP_CUSTOMER_API',
    'REACT_APP_USER_API',
    'REACT_APP_PLANT_API',
    'REACT_APP_EQUIPMENT_UNIT_API',
    'REACT_APP_EQUIPMENT_MODEL_API',
    'REACT_APP_EQUIPMENT_UNIT_DATA_MONITORING_API',
    'REACT_APP_GOOGLE_MAPS_API_KEY',
  ];

  const missingVars: string[] = requiredEnvVars.filter((envVar: string) => !process.env[envVar]);

  if (missingVars.length > 0) {
    throw new Error(`Missing environment variables: ${missingVars.join(', ')}`);
  }
};

export function appLocaleToDayjs(locale: string | null | undefined): string {
  let result = '';

  // Validate the locale parameter and process it if valid
  if (typeof locale === 'string' && locale.trim() !== '') {
    result = locale.replace('_', '-').toLowerCase();
  }

  return result;
}

export const capitalizeFirstLetter = (str?: string | null): string | null => (!_.isNil(str)
  ? str.charAt(0).toUpperCase() + str.slice(1)
  : null);

/* **************** MEASUREMENT CONVERSION **************** */

export const convertTemperature = (
  value: number | null | undefined,
  unit: TemperatureType,
  tempDiff?: null | boolean,
): number | null | undefined => {
  let result: number | null | undefined = value;

  if (_.isNil(value)) {
    result = value;
  } else if (unit === TemperatureType.f) {
    if (tempDiff) {
      result = (value * 9) / 5;
    } else {
      result = (value * 9) / 5 + 32;
    }
  }

  return result;
};

export const convertPressure = (
  value: number | null | undefined,
  unit: PressureType,
): number | null | undefined => {
  let result: number | null | undefined = value;

  if (_.isNil(value)) {
    result = value;
  } else if (unit === PressureType.bar) {
    result = value * 10;
  } else if (unit === PressureType.psi) {
    result = value * 145.038;
  }

  return result;
};

export const convertLength = (
  value: number | null | undefined,
  unit: LengthType,
): number | null | undefined => {
  let result: number | null | undefined = value;

  if (_.isNil(value)) {
    result = value;
  } else if (unit === LengthType.inch) {
    result = value * 0.0393701;
  }

  return result;
};

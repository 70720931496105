// Core
import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';

// Libraries
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../plugins/request';

// Components
import { AdminPanel } from '../../../layout/AdminPanel';
import { Breadcrumb, BreadcrumbItem, BreadcrumbType } from '../../../layout/elements/Breadcrumb';
import { PageHeader } from '../../../layout/elements/PageHeader';
import { CardEquipment, CardEquipmentProps } from '../../../components/CardEquipment';

// Component
import { CardsContainer, Container } from './EquipmentModelsIndex.style';

// Media
import Crusher from '../../../media/images/equipment/crusher.png';
import Roll from '../../../media/images/equipment/roll.png';
import Screen from '../../../media/images/equipment/screen.png';
import Washer from '../../../media/images/equipment/washer.png';
import Conveyor from '../../../media/images/equipment/conveyor2.png';

function EquipmentModelsIndex(): JSX.Element {
  // Dependencies
  const navigate = useNavigate();
  const { t } = useTranslation();

  /* **********************************************************************************************
  ************************************ INITIAL STATES *********************************************
  *********************************************************************************************** */

  interface EquipmentModelState {
    crusher: {
      equipmentModelsCount: number | null;
      equipmentUnitsCount: number | null;
    }
    roll: {
      equipmentModelsCount: number | null;
      equipmentUnitsCount: number | null;
    },
    screen: {
      equipmentModelsCount: number | null;
      equipmentUnitsCount: number | null;
    },
    washer: {
      equipmentModelsCount: number | null;
      equipmentUnitsCount: number | null;
    },
    conveyor: {
      equipmentModelsCount: number | null;
      equipmentUnitsCount: number | null;
    },
  }

  const equipmentModelState: EquipmentModelState = {
    crusher: {
      equipmentModelsCount: null,
      equipmentUnitsCount: null,
    },
    roll: {
      equipmentModelsCount: null,
      equipmentUnitsCount: null,
    },
    screen: {
      equipmentModelsCount: null,
      equipmentUnitsCount: null,
    },
    washer: {
      equipmentModelsCount: null,
      equipmentUnitsCount: null,
    },
    conveyor: {
      equipmentModelsCount: null,
      equipmentUnitsCount: null,
    },
  };

  const [state, setState] = useState<EquipmentModelState>(equipmentModelState);

  /* **********************************************************************************************
  *********************************** COMPONENT HANDLING ******************************************
  *********************************************************************************************** */

  const breadcrumbItems: BreadcrumbItem[] = [
    {
      text: t('view.equipmentModel.title'),
      target: '/equipment-models',
    },
  ];

  const equipmentModels: CardEquipmentProps[] = useMemo(() => [
    {
      imgSrc: Crusher,
      registeredModels: state.crusher.equipmentModelsCount,
      registeredUnits: state.crusher.equipmentUnitsCount,
      onClick: () => navigate('#'),
      title: t('common.equipmentCategory.crusher.plural'),
    },
    {
      imgSrc: Roll,
      registeredModels: state.roll.equipmentModelsCount,
      registeredUnits: state.roll.equipmentUnitsCount,
      onClick: () => navigate('#'),
      title: t('common.equipmentCategory.roll.plural'),
    },
    {
      imgSrc: Screen,
      registeredModels: state.screen.equipmentModelsCount,
      registeredUnits: state.screen.equipmentUnitsCount,
      onClick: () => navigate('#'),
      title: t('common.equipmentCategory.screen.plural'),
    },
    {
      imgSrc: Washer,
      registeredModels: state.washer.equipmentModelsCount,
      registeredUnits: state.washer.equipmentUnitsCount,
      onClick: () => navigate('#'),
      title: t('common.equipmentCategory.washer.plural'),
    },
    {
      imgSrc: Conveyor,
      registeredModels: state.conveyor.equipmentModelsCount,
      registeredUnits: state.conveyor.equipmentUnitsCount,
      onClick: () => navigate('#'),
      title: t('common.equipmentCategory.conveyor.plural'),
    },
  ], [state, t, navigate]);

  useEffect(() => {
    requestVantageApi.equipmentModel({
      method: RequestMethod.GET,
      path: '/stats',
    }).then((response) => {
      setState((prevState) => ({
        ...prevState,
        crusher: {
          equipmentModelsCount: response.crusher.registeredModels,
          equipmentUnitsCount: response.crusher.registeredUnits,
        },
      }));
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <AdminPanel>
      <Container>
        <Breadcrumb items={breadcrumbItems} type={BreadcrumbType.page} />
        <PageHeader
          title={`${t('view.equipmentModel.title')}`}
          description={`${t('view.equipmentModel.description')}`}
        />
        <CardsContainer>
          {equipmentModels.map((equipmentModel: CardEquipmentProps) => (
            <div key={equipmentModel.title}>
              <CardEquipment
                testId="card-equipment-test-id"
                imgSrc={equipmentModel.imgSrc}
                registeredModels={equipmentModel.registeredModels}
                registeredUnits={equipmentModel.registeredUnits}
                onClick={equipmentModel.onClick}
                title={equipmentModel.title}
              />
            </div>
          ))}
        </CardsContainer>
      </Container>
    </AdminPanel>
  );
}

export { EquipmentModelsIndex };

// Core
import styled from 'styled-components';

// Libraries
import { Modal as AntDModal } from 'antd';

// Component
import { ModalPanelProps, ModalSize } from './ModalPanel.types';

export const Element = styled.div`
  flex: 1;
`;

export const Header = styled.div<Pick<ModalPanelProps, 'description'>>`
  align-items: flex-start;
  justify-content: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Container = styled(AntDModal)<{ size?: ModalSize.sm | ModalSize.lg }>`

  .modal-header-description {
    color: ${({ theme }) => theme.color.modalPanel.headerDescriptionTextColor};
  };
  
  .ant-modal-body {
    height: auto;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
  };
  
  .ant-modal-content {
    border-radius: 4px;
    padding: 2em 2em 16px 2em;
  };
`;

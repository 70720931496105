// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  runningStatusToCountershaftIndicator,
  runningStatusToIndicatorStatus,
} from '../../../../../plugins/crusher';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import { EdgeDeviceOperationalStatus } from '../../../../../types/global';

// Components
import { Paragraph, ParagraphWeight } from '../../../../../layout/elements/Paragraph';
import { StatusIndicator } from '../../../../../layout/elements/StatusIndicator';

// View
import {
  IndicatorsContainer,
  StatusContainer,
  TitleContainer,
} from './IndicatorsModule.style';
import { CrusherIndicatorsModuleProps } from './IndicatorsModule.types';

function IndicatorsModule({
  className,
  operationalData,
  testId,
}: CrusherIndicatorsModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  return (
    <IndicatorsContainer
      data-testid={testId}
      className={className}
    >
      <TitleContainer>
        <Paragraph weight={ParagraphWeight.bold}>
          {t('view.crusher.runningStatus.title')}
        </Paragraph>
      </TitleContainer>
      <StatusContainer>
        <StatusIndicator
          text={t('view.crusher.runningStatus.hydUnit')}
          status={runningStatusToIndicatorStatus(
            lastUnitData?.hydraulicPumpRunningStatus,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.motor')}
          status={runningStatusToIndicatorStatus(
            lastUnitData?.crusherMotor,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.lubeUnit')}
          status={runningStatusToIndicatorStatus(
            lastUnitData?.lubePumpRunningStatus,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.discharge')}
          status={runningStatusToIndicatorStatus(
            lastUnitData?.dischargeRunningStatus,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.feeder')}
          status={runningStatusToIndicatorStatus(
            lastUnitData?.feederRunningStatus,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )}
          className="status"
        />
        <StatusIndicator
          text={t('view.crusher.runningStatus.blower')}
          status={runningStatusToCountershaftIndicator(
            lastUnitData?.countershaftBlowerRunningStatus,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )}
          className="status"
        />
      </StatusContainer>
    </IndicatorsContainer>
  );
}

export { IndicatorsModule };

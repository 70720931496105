import {
  EdgeDeviceOperationalStatus,
  EquipmentCategory,
  EquipmentUnitInstallStatus,
} from './global';

/* ***************************************** ENUMS ********************************************** */

export enum CrusherFeederAutomationMode {
  manual = 'manual',
  auto = 'auto',
}

export enum CrusherMotorControl {
  manual = 'manual',
  auto = 'auto',
}

export enum CrusherOperationMode {
  local = 'local',
  remote = 'remote',
  service = 'service',
}

export enum CrusherOverallStatus {
  good = 'good',
  warning = 'warning',
  alarm = 'alarm',
}

export enum CrusherRunningSStatus {
  starting = 'starting',
  running = 'running',
  fault = 'fault',
  off = 'off',
}

export enum CrusherRunningEStatus {
  enabled = 'enabled',
  fault = 'fault',
  off = 'off',
  running = 'running',
}

export enum CrusherWeatherModeStatus {
  on = 'on',
  off = 'off',
}

export enum CrusherDashboardChartRange {
  fiveMinutes = 'fiveMinutes',
  week = 'week',
  month = 'month',
}

export enum CrusherWebsocketRequestEvents {
  realTimeCrusherData = 'realTimeCrusherData',
}

export enum CrusherWebsocketResponseEvents {
  equipmentUnitCrusherData = 'equipmentUnitCrusherData',
}

export enum CrusherModel {
  p200 = 'p200',
  p300 = 'p300',
  p400 = 'p400',
  p500 = 'p500',
  p600 = 'p600',
}

export enum CrusherPLCTagSchema {
  t1patriot = 't1patriot',
  t2patriot = 't2patriot',
}

export enum CrusherDataSchema {
  d1patriot = 'd1patriot',
  d2patriot = 'd2patriot',
}

/* *************************************** TRENDINGS ******************************************** */

export enum TrendingType {
  motorCurrent = 'motorCurrent',
  pid = 'pid',
  lubeUnit = 'lubeUnit',
  hydraulic = 'hydraulic',
  ringBounceSensor = 'ringBounceSensor',
}

/* *************************************** INTERFACES ******************************************* */

export interface CrusherSchemas {
  [CrusherModel.p200]: Record<CrusherPLCTagSchema, CrusherDataSchema[]>;
  [CrusherModel.p300]: Record<CrusherPLCTagSchema, CrusherDataSchema[]>;
  [CrusherModel.p400]: Record<CrusherPLCTagSchema, CrusherDataSchema[]>;
  [CrusherModel.p500]: Record<CrusherPLCTagSchema, CrusherDataSchema[]>;
  [CrusherModel.p600]: Record<CrusherPLCTagSchema, CrusherDataSchema[]>;
}

export interface EquipmentUnitCrusher {
  createdAt: number;
  createdBy: string;
  customerUnitName: string;
  dataSchema: CrusherDataSchema;
  deletedAt?: number | null;
  deletedBy?: string | null;
  equipmentCategory: EquipmentCategory;
  id: string;
  idArea?: string | null;
  idSite: string;
  installedAt: number;
  installedBy: string;
  installStatus: EquipmentUnitInstallStatus;
  model: CrusherModel;
  plcTagSchema: CrusherPLCTagSchema;
  serialNumber: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
}

export interface EquipmentUnitDataCrusher {
  // *** EquipmentUnitData *** //
  idEquipmentUnit: string;
  equipmentCategory: EquipmentCategory;
  timestamp: number | null;
  unitStatus: CrusherRunningSStatus | null;

  // *** EquipmentUnitDataCrusher *** //
  beltSlipPermissive: boolean | null;
  bowlCloseRequest: boolean | null;
  bowlMovementSensorProx1: boolean | null;
  bowlMovementSensorProx2: boolean | null;
  bowlOpenRequest: boolean | null;
  calibrationActive: boolean | null;
  cavityLevel: number | null;
  cavityLevelTransducerFault: boolean | null;
  clampPressureSensorFault: boolean | null;
  coolerFanStatus: CrusherRunningSStatus | null;
  countershaftBlowerRunningStatus: boolean | null;
  countershaftBushingTemp: number | null;
  countershaftBushingTransducerFault: boolean | null;
  countershaftEccentricTemp: number | null;
  crusherAboveMinimumCSS: boolean | null;
  crusherBeltSlip: boolean | null;
  crusherBowlCreep: boolean | null;
  crusherMotor: CrusherRunningSStatus | null;
  crusherMotor1Fault: boolean | null;
  crusherMotor1RunHours: number | null;
  crusherMotor2Fault: boolean | null;
  crusherMotorAmps: number | null;
  crusherMotorOverallStatus: CrusherOverallStatus | null;
  crusherMotorRpm: number | null;
  crusherOperationMode: boolean | null;
  crusherStartPermissives: boolean | null;
  csSetting: number | null;
  currentLinerCalibrationCount: number | null;
  currentLinerHoursSinceLastCalibration: number | null;
  dischargeRunHours: number | null;
  dischargeRunningStatus: CrusherRunningSStatus | null;
  eStop: boolean | null;
  eccentrictBushingTransducerFault: boolean | null;
  feederAutomation: CrusherFeederAutomationMode | null;
  feederRunHours: number | null;
  feederRunningStatus: CrusherRunningSStatus | null;
  feederPermissives: boolean | null;
  feederStarterFault: boolean | null;
  highCavityLevel: boolean | null;
  highCounterShaftBushingTemp: boolean | null;
  highCrusherCurrentPermissive: boolean | null;
  highEccentricBushingTemp: boolean | null;
  highOilTemp: boolean | null;
  highVibrationPermissive: boolean | null;
  hydExcessiveClampPressure: boolean | null;
  hydExcessiveTrampPressure: boolean | null;
  hydLowTrampPressure: boolean | null;
  hydLowClampPressure: boolean | null;
  hydPumpRunningOver1Hour: boolean | null;
  hydraulicClampPressure: number | null;
  hydraulicOverallStatus: CrusherOverallStatus | null;
  hydraulicPermissive: boolean | null;
  hydraulicPumpRunningStatus: CrusherRunningSStatus | null;
  hydraulicTankTempStatus: CrusherOverallStatus | null;
  hydraulicTrampPressure: number | null;
  linerInstalledDateDay: number | null;
  linerInstalledDateMonth: number | null;
  linerInstalledDateYear: number | null;
  linerWear: number | null;
  lowLubeOil: boolean | null;
  linerWearRemainingPercent: number | null;
  lowLubeOilPressure: boolean | null;
  lubeDifferentialTempPermissive: boolean | null;
  lubeHeaterPermissive: boolean | null;
  lubeHeaterStarterFault: boolean | null;
  lubeHighReturnTempPermissive: boolean | null;
  lubeOilLevelLow: boolean | null;
  lubeOilPressure: boolean | null;
  lubePermissive: boolean | null;
  lubePressureTransducerFault: boolean | null;
  lubePump1RunHours: number | null;
  lubePump1StarterFault: boolean | null;
  lubePumpRunningStatus: CrusherRunningSStatus | null;
  lubePumpStarterFault: boolean | null;
  lubeReturnLowTemp: boolean | null;
  lubeReturnTempHigh: boolean | null;
  lubeReturnTempTransducerFault: boolean | null;
  lubeSupplyDifferential: number | null;
  lubeTankHeaterEnable: boolean | null;
  lubeTankLevel: boolean | null;
  lubeTankOilLevel: boolean | null;
  lubeTankTempTransducerFault: boolean | null;
  lubricationOilPressure: number | null;
  lubricationOverallStatus: CrusherOverallStatus | null;
  lubricationReturnTemperature: number | null;
  lubricationSupplyTemperature: number | null;
  lubricationTankTemperature: number | null;
  minCrusherRunTimer: boolean | null;
  minimumLubeRunTimer: boolean | null;
  motorControl: CrusherMotorControl | null;
  numberOfTeeth: number | null;
  oilLevelLow: boolean | null;
  operationModeLocalRemote: CrusherOperationMode | null;
  operationModeOperateService: CrusherOperationMode | null;
  pumpOverPressure: boolean | null;
  spAmpAdjustHighAmpsEnable: boolean | null;
  spAmpCloseAlarmTeeth: number | null;
  spAmpCountFeedStoppage: number | null;
  spAmpOpenAlarmTeeth: number | null;
  spAmpShiftTimeHours: number | null;
  spAutoBowlAdjustLowAmpsEnable: boolean | null;
  spAutoBowlAdjustVibrationEnable: boolean | null;
  spAutoCloseMinCss: number | null;
  spAutoModeSelection: number | null;
  spBeltSlipAlarmRpm: number | null;
  spBowlAdjustmentMaxClampPressure: number | null;
  spBowlAdjustmentUnderLoadBool: boolean | null;
  spCavityLevelMaxLevel: number | null;
  spClampPressureLowAlarm: number | null;
  spClampPressureScaleMax: number | null;
  spClampPressureScaleMin: number | null;
  spClampPressureStartPressurize: number | null;
  spClampPressureStopPressurize: number | null;
  spCounterShaftBushingScaleMax: number | null;
  spCounterShaftBushingScaleMin: number | null;
  spCounterShaftTempWarning: number | null;
  spCrusherAdjustmentWarningHornTime: number | null;
  spCrusherMotorAmpsScaleMax: number | null;
  spCrusherMotorAmpsScaleMin: number | null;
  spCrusherMotorAutoAmps: number | null;
  spCrusherMotorAutoAmpsHighLimit: number | null;
  spCrusherMotorAutoAmpsLowLimit: number | null;
  spCrusherMotorCrushingAmps: number | null;
  spCrusherMotorFullLoadAmps: number | null;
  spCrusherMotorRpmScaleMax: number | null;
  spCrusherMotorRpmScaleMin: number | null;
  spCrusherMotorRunningAmps: number | null;
  spCrusherMotorServiceFactor: number | null;
  spCrusherMotorWarningHornTime: number | null;
  spDischargeWarningHornTime: number | null;
  spEccentricBushingScaleMax: number | null;
  spEccentricBushingScaleMin: number | null;
  spEccentricBushingTempHigh: number | null;
  spEccentricBushingTempHighStopCrusher: number | null;
  spEccentricBushingTempHighStopFeed: number | null;
  spFeedCavityLevel: number | null;
  spFeedCavityLevelHighLimit: number | null;
  spFeederWarningHornTime: number | null;
  spLowAmpsRespTime: number | null;
  spLubeCoolerStartTemp: number | null;
  spLubeCoolerStopTemp: number | null;
  spLubeHeaterStartTemp: number | null;
  spLubeHeaterStopTemp: number | null;
  spLubePressureLowAlarm: number | null;
  spLubePressureLowWarning: number | null;
  spLubePressureNormal: number | null;
  spLubePressureStopAlarm: number | null;
  spLubeReturnTempCrusherStartPermissive: number | null;
  spLubeReturnTempCrusherStop: number | null;
  spLubeReturnTempHighWarn: number | null;
  spLubeReturnTempLowAlarm: number | null;
  spLubeReturnTempLowWarning: number | null;
  spLubeReturnTempStopFeed: number | null;
  spLubeSupplyReturnTempDiffAlarm: number | null;
  spLubeSupplyReturnTempDiffDelay: number | null;
  spLubeSupplyTempLowWarn: number | null;
  spLubeTankTempHighAlarm: number | null;
  spLubeTankTempHighWarning: number | null;
  spLubeTankTempLowAlarm: number | null;
  spLubeTankTempLowWarning: number | null;
  spMaxFeederSpeed: number | null;
  spPidAutoLevelFeederSpeedKd: number | null;
  spPidAutoLevelFeederSpeedKi: number | null;
  spPidAutoLevelFeederSpeedKp: number | null;
  spReturnTempScaleMax: number | null;
  spReturnTempScaleMin: number | null;
  spSupplyPressureScaleMax: number | null;
  spSupplyPressureScaleMin: number | null;
  spSupplyTempScaleMin: number | null;
  spSupplyTempScaleMax: number | null;
  spSystemPressureHighAlarm: number | null;
  spSystemPressureScaleMax: number | null;
  spSystemPressureScaleMin: number | null;
  spTankTempScaleMax: number | null;
  spTankTempScaleMin: number | null;
  spTimeAboveFlaFeedStop: number | null;
  spTimeAboveFlaSf115FeedStop: number | null;
  spTimeAboveFlaSfFeedStop: number | null;
  spTrampPressureLowAlarm: number | null;
  spTrampPressureScaleMax: number | null;
  spTrampPressureScaleMin: number | null;
  spTrampPressureStartPressurize: number | null;
  spTrampPressureStopPressurize: number | null;
  spVibAlarmTeethToOpen: number | null;
  spVibCountFeedStoppage: number | null;
  spVibScaleMax: number | null;
  spVibScaleMin: number | null;
  spVibShiftTimeHours: number | null;
  spVibration1Warning: number | null;
  spVibration2Warning: number | null;
  spVibration3Warning: number | null;
  spVibration4Warning: number | null;
  systemControlPower: boolean | null;
  tankLubeHeaterStatus: CrusherRunningEStatus | null;
  tankTempTooLowToRun: boolean | null;
  tankTempTooLowToStart: boolean | null;
  trampPressureSensorFault: boolean | null;
  vibrationSensor1: number | null;
  vibrationSensor2: number | null;
  vibrationSensor3: number | null;
  // vibrationSensor4: number | null; // @TODO Validate the 4th vibration sensor after first release
  weatherModeStatus: CrusherWeatherModeStatus | null;
}

export type CrusherOperationalData = (
  EquipmentUnitDataCrusher | EdgeDeviceOperationalStatus
)[];

// Core
import React from 'react';

// Libraries
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

// Plugins
import { timestampToDateStrText, timestampToTimeAgo } from '../../../../../plugins/general';
import {
  formatCrusherModel,
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  runningStatusToEquipmentStatus,
} from '../../../../../plugins/crusher';

// Types
import { Theme } from '../../../../../types/theme';
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';

// Components
import { ConnectionStatus, ConnectionStatusStatus } from '../../../../../components/ConnectionStatus';
import { EquipmentStatusTag } from '../../../../../components/EquipmentStatusTag';
import {
  Button,
  ButtonSize,
  ButtonType,
} from '../../../../../layout/elements/Button';
import { Breadcrumb, BreadcrumbType } from '../../../../../layout/elements/Breadcrumb';
import {
  Paragraph,
  ParagraphAlign,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../layout/elements/Row';
import {
  Title,
  TitleSize,
  TitleWeight,
} from '../../../../../layout/elements/Title';
import { Tooltip } from '../../../../../layout/elements/Tooltip';
import {
  Alarm,
  Information,
  LineChart,
  ListCheck,
  Settings,
  Tools,
} from '../../../../../layout/icons';

// View
import {
  AlarmButton,
  ButtonContainer,
  ContentContainer,
  CrusherInfoContainer,
  CrusherStatus,
  DetailsContainer,
  EquipmentName,
  EquipmentStatusContainer,
  LastUpdateTime,
  OverviewContainer,
} from './DetailsModule.style';
import { CrusherDetailsModuleProps } from './DetailsModule.types';

function DetailsModule({
  breadcrumbItems,
  className,
  crusherModel,
  customerUnitName,
  equipmentUnitCrusher,
  openTrending,
  operationalData,
  testId,
}: CrusherDetailsModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  // Function to format the equipment title
  const formattedTitle = (): JSX.Element => (
    <Title size={TitleSize.xxs} weight={TitleWeight.bold} className="title">
      {customerUnitName || formatCrusherModel(crusherModel)}
    </Title>
  );

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  return (
    <DetailsContainer
      className={className}
      data-testid={testId}
    >
      <ContentContainer>
        <Row>
          <Breadcrumb
            type={BreadcrumbType.plant}
            items={breadcrumbItems}
            testId="breadcrumb"
          />
        </Row>
        <CrusherStatus>
          <EquipmentStatusContainer>
            <EquipmentStatusTag
              status={
                runningStatusToEquipmentStatus(
                  lastUnitData?.unitStatus,
                  lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                )
              }
              testId="equipment-status"
            />
          </EquipmentStatusContainer>
          <EquipmentName>
            {formattedTitle()}
          </EquipmentName>
          <Paragraph
            size={ParagraphSize.sm}
            color={theme.color.crusherDashboard.detailsModule.lastUpdateTextColor}
            testId="last-update-item"
          >
            <Trans
              i18nKey="common.lastUpdate"
              values={{ lastUpdate: timestampToTimeAgo(lastEdgeStatus?.timestamp) }}
              components={{ lastUpdateTime: <LastUpdateTime /> }}
            />
          </Paragraph>
        </CrusherStatus>

        <OverviewContainer>
          <CrusherInfoContainer>
            <Paragraph weight={ParagraphWeight.bold} className="overview-section">
              {t('common.overview')}
            </Paragraph>
          </CrusherInfoContainer>

          <CrusherInfoContainer>
            <Paragraph
              size={ParagraphSize.sm}
              color={theme.color.crusherDashboard.detailsModule.parametersTextColor}
              className="paragraph-sm"
            >
              {t('common.cloudConnection')}
            </Paragraph>
            <ConnectionStatus
              status={
                lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on
                  ? ConnectionStatusStatus.online
                  : ConnectionStatusStatus.offline
              }
              testId="connection-status"
              className="connection-status"
            />
          </CrusherInfoContainer>

          <CrusherInfoContainer>
            <Paragraph
              size={ParagraphSize.sm}
              color={theme.color.crusherDashboard.detailsModule.parametersTextColor}
              className="paragraph-sm"
            >
              {t('common.superiorPartNumber')}
            </Paragraph>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              align={ParagraphAlign.right}
              color={theme.color.crusherDashboard.detailsModule.valuesTextColor}
              className="paragraph-sm"
            >
              {formatCrusherModel(crusherModel)}
            </Paragraph>
          </CrusherInfoContainer>

          <CrusherInfoContainer>
            <Paragraph
              size={ParagraphSize.sm}
              className="paragraph-sm"
              color={theme.color.crusherDashboard.detailsModule.parametersTextColor}
            >
              {t('common.serialNumber')}
            </Paragraph>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              align={ParagraphAlign.right}
              color={theme.color.crusherDashboard.detailsModule.valuesTextColor}
              className="paragraph-sm"
            >
              {equipmentUnitCrusher.serialNumber}
            </Paragraph>
          </CrusherInfoContainer>

          <CrusherInfoContainer>
            <Paragraph
              size={ParagraphSize.sm}
              color={theme.color.crusherDashboard.detailsModule.parametersTextColor}
              className="paragraph-sm"
            >
              {t('common.installedAt')}
            </Paragraph>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              align={ParagraphAlign.right}
              color={theme.color.crusherDashboard.detailsModule.valuesTextColor}
              className="paragraph-sm"
            >
              {timestampToDateStrText(equipmentUnitCrusher.installedAt)}
            </Paragraph>
          </CrusherInfoContainer>
        </OverviewContainer>

        <ButtonContainer>
          <Tooltip className="tooltip" text={t('common.settings')}>
            <Button
              square
              className="button-actions"
              size={ButtonSize.xs}
              disabled
              type={ButtonType.secondary}
              testId="settings-button"
              onClick={() => {}}
            >
              <Settings />
            </Button>
          </Tooltip>
          <Tooltip className="tooltip" text={t('common.trending')}>
            <Button
              square
              className="button-actions"
              size={ButtonSize.xs}
              type={ButtonType.secondary}
              testId="trending-button"
              onClick={openTrending}
            >
              <LineChart />
            </Button>
          </Tooltip>
          <Tooltip className="tooltip" text={t('common.maintenance')}>
            <Button
              square
              className="button-actions"
              disabled
              size={ButtonSize.xs}
              type={ButtonType.secondary}
              testId="maintenance-button"
              onClick={() => {}}
            >
              <Tools />
            </Button>
          </Tooltip>
          <Tooltip className="tooltip" text={t('common.timeline')}>
            <Button
              square
              disabled
              className="button-actions"
              size={ButtonSize.xs}
              type={ButtonType.secondary}
              testId="timeline-button"
              onClick={() => {}}
            >
              <ListCheck />
            </Button>
          </Tooltip>
          <Tooltip className="tooltip" text={t('common.info')}>
            <Button
              square
              disabled
              className="button-actions"
              size={ButtonSize.xs}
              type={ButtonType.secondary}
              testId="info-button"
              onClick={() => {}}
            >
              <Information />
            </Button>
          </Tooltip>
          <Tooltip className="tooltip" text={t('common.alarm.plural')}>
            <AlarmButton
              size={ButtonSize.xs}
              square
              disabled
              className="button-actions"
              type={ButtonType.secondary}
              testId="alarm-button"
              onClick={() => {}}
            >
              <Alarm />
              {/* @TODO: apply the logic of alarms */}
              {/* {data.alarms >= 1 && ( */}
              {/*  <div className="alarms-indicator"> */}
              {/*    {data.alarms} */}
              {/*  </div> */}
              {/* )} */}
            </AlarmButton>
          </Tooltip>
        </ButtonContainer>
      </ContentContainer>
    </DetailsContainer>
  );
}

export { DetailsModule };

// Core
import React, { useEffect } from 'react';

// Libraries
import { Progress as ProgressAntD } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import _ from 'lodash';

// Component
import {
  Container,
  ContentContainer,
  DataValue,
  MaxValueContainer,
  MaxValueNumber,
  MaxValueTick,
  MinValueContainer,
  MinValueNumber,
  MinValueTick,
  SpeedometerEffect,
  TitleContainer,
  UnitType,
} from './Speedometer.style';
import { SpeedometerProps } from './Speedometer.types';

// Types
import { Theme } from '../../../types/theme';

// Plugins
import { currentValuePercentage, formatNumber } from '../../../plugins/general';

// Layout
import {
  Paragraph,
  ParagraphAlign,
  ParagraphSize,
  ParagraphWeight,
} from '../Paragraph';

function Speedometer({
  className,
  decimalPlaces = 0,
  maxValue,
  minValue,
  running = true,
  testId,
  thresholds,
  title,
  unit,
  value,
}: SpeedometerProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();
  const { i18n } = useTranslation();

  // UseEffect to re-render on language change
  useEffect((): void => {
    // Force update component when language changes
  }, [i18n.language]);

  // Triggers errors at unaccepted thresholds
  if (
    !_.isNil(thresholds.lowAlarm)
    && !_.isNil(thresholds.lowWarning)
    && thresholds.lowAlarm >= thresholds.lowWarning
  ) {
    throw new Error('[lowAlarm] must be less than [lowWarning]');
  }
  if (
    !_.isNil(thresholds.lowAlarm)
    && !_.isNil(thresholds.highWarning)
    && thresholds.lowAlarm >= thresholds.highWarning
  ) {
    throw new Error('[lowAlarm] must be less than [highWarning]');
  }
  if (
    !_.isNil(thresholds.lowAlarm)
    && !_.isNil(thresholds.highAlarm)
    && thresholds.lowAlarm >= thresholds.highAlarm
  ) {
    throw new Error('[lowAlarm] must be less than [highAlarm]');
  }
  if (
    !_.isNil(thresholds.lowWarning)
    && !_.isNil(thresholds.highWarning)
    && thresholds.lowWarning >= thresholds.highWarning
  ) {
    throw new Error('[lowWarning] must be less than [highWarning]');
  }
  if (
    !_.isNil(thresholds.lowWarning)
    && !_.isNil(thresholds.highAlarm)
    && thresholds.lowWarning >= thresholds.highAlarm
  ) {
    throw new Error('[lowWarning] must be less than [highAlarm]');
  }
  if (
    !_.isNil(thresholds.highWarning)
    && !_.isNil(thresholds.highAlarm)
    && thresholds.highWarning >= thresholds.highAlarm
  ) {
    throw new Error('[highWarning] must be less than [highAlarm]');
  }

  const {
    highAlarm,
    highWarning,
    lowAlarm,
    lowWarning,
  } = thresholds;

  const percentage: number = _.isNil(value) || _.isNil(minValue) || _.isNil(maxValue)
    ? 0
    : currentValuePercentage({
      value,
      minValue,
      maxValue,
    });

  let currentColor: string;

  // Determines the current speedometer color based on calculated percentages
  if (!running) {
    currentColor = theme.color.speedometer.offProgressBackgroundColor;
  } else if (
    !_.isNil(value)
    && (
      (!_.isNil(highAlarm) && value >= highAlarm)
      || (!_.isNil(lowAlarm) && value <= lowAlarm)
    )
  ) {
    currentColor = theme.color.speedometer.alarmBackgroundColor;
  } else if (
    !_.isNil(value)
    && (
      (!_.isNil(highWarning) && value >= highWarning)
      || (!_.isNil(lowWarning) && value <= lowWarning)
    )
  ) {
    currentColor = theme.color.speedometer.warningBackgroundColor;
  } else {
    currentColor = theme.color.speedometer.normalBackgroundColor;
  }

  // Function to render the speedometer data, including the value and unit
  const speedometerData = ({
    value: dataValue,
    unit: dataUnit,
  }: Pick<SpeedometerProps, 'value' | 'unit'>): React.ReactNode => {
    const formattedValue: string = formatNumber(dataValue, decimalPlaces);

    return (
      <div>
        <DataValue>
          <Paragraph align={ParagraphAlign.center}>
            {formattedValue}
          </Paragraph>
        </DataValue>
        <UnitType>
          <Paragraph
            align={ParagraphAlign.center}
            color={theme.color.speedometer.unitTextColor}
          >
            {dataUnit}
          </Paragraph>
        </UnitType>
      </div>
    );
  };

  return (
    <Container
      data-testid={testId}
      className={className}
    >
      <ContentContainer>
        <MinValueContainer>
          <MinValueTick />
          <MinValueNumber>
            <Paragraph
              size={ParagraphSize.xs}
              color={theme.color.speedometer.ticksValuesTextColor}
            >
              {formatNumber(minValue, decimalPlaces)}
            </Paragraph>
          </MinValueNumber>
        </MinValueContainer>

        <MaxValueContainer>
          <MaxValueTick />
          <MaxValueNumber>
            <Paragraph
              size={ParagraphSize.xs}
              color={theme.color.speedometer.ticksValuesTextColor}
            >
              {formatNumber(maxValue, decimalPlaces)}
            </Paragraph>
          </MaxValueNumber>
        </MaxValueContainer>

        <ProgressAntD
          strokeLinecap="round"
          type="dashboard"
          format={() => speedometerData({ value, unit })}
          percent={percentage}
          strokeColor={currentColor}
          strokeWidth={3}
          gapDegree={100}
        />
        {!_.isNil(value) && <SpeedometerEffect />}
      </ContentContainer>
      <TitleContainer>
        <Paragraph
          size={ParagraphSize.sm}
          weight={ParagraphWeight.medium}
          color={theme.color.speedometer.titleTextColor}
        >
          {title}
        </Paragraph>
      </TitleContainer>
    </Container>
  );
}

export { Speedometer };

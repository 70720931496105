// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Types
import { EdgeDeviceOperationalStatus } from '../../../../../../types/global';
import { EquipmentUnitDataCrusher } from '../../../../../../types/crusher';

// Store
import { MeasurementType } from '../../../../../../store/measurement.store';

// Components
import {
  LineChart,
  LineChartType,
  Series,
} from '../../../../../../layout/elements/LineChart';

// View
import { TrendingsHydraulicUnitProps } from './TrendingsHydraulicUnit.types';

function TrendingsHydraulicUnit({
  className,
  operationalData,
  testId,
}: TrendingsHydraulicUnitProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const series: Series[] = [
    {
      name: t('common.cloudConnection'),
      field: 'edgeStatus',
      type: LineChartType.fill,
      date: 'timestamp',
      opposite: true,
    },
    {
      name: t('view.crusher.hydraulic.trampPressure'),
      field: 'hydraulicTrampPressure',
      type: LineChartType.line,
      date: 'timestamp',
      color: 'green',
      unit: 'PSI',
      bullet: true,
      measurementType: MeasurementType.pressure,
    },
    {
      name: t('view.crusher.hydraulic.clampPressure'),
      field: 'hydraulicClampPressure',
      type: LineChartType.line,
      measurementType: MeasurementType.pressure,
      date: 'timestamp',
      color: 'blue',
      unit: 'PSI',
      bullet: true,
    },
  ];

  const convertedEdgeStatus: (
    EquipmentUnitDataCrusher | { edgeStatus: number, timestamp: number }
  )[] = operationalData.map((item: EquipmentUnitDataCrusher | EdgeDeviceOperationalStatus) => (
    'edgeStatus' in item
      ? { ...item, edgeStatus: item.edgeStatus === 'on' ? 1 : 0 }
      : item));

  return (
    <LineChart
      activeBullet
      className={className}
      data={convertedEdgeStatus}
      id="hydraulic-unit"
      series={series}
      testId={testId}
      timeUnit="second"
    />
  );
}

export { TrendingsHydraulicUnit };

// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Types
import { EdgeDeviceOperationalStatus } from '../../../../../../types/global';
import { EquipmentUnitDataCrusher } from '../../../../../../types/crusher';

// Store
import { MeasurementType } from '../../../../../../store/measurement.store';

// Components
import {
  LineChart,
  LineChartType,
  Series,
  SeriesColorEnum,
} from '../../../../../../layout/elements/LineChart';

// View
import { TrendingsPIDProps } from './TrendingsPID.types';

function TrendingsPID({
  className,
  operationalData,
  testId,
}: TrendingsPIDProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const series: Series[] = [
    {
      name: t('common.cloudConnection'),
      field: 'edgeStatus',
      type: LineChartType.fill,
      date: 'timestamp',
      opposite: true,
      measurementType: MeasurementType.temperature,
    },
    {
      name: t('view.crusher.trendings.pid.legend.currentCrusherAmps'),
      field: 'crusherMotorAmps',
      type: LineChartType.line,
      date: 'timestamp',
      unit: 'A',
      decimals: 2,
      color: SeriesColorEnum.green,
      bullet: true,
    },
    {
      name: t('view.crusher.trendings.pid.legend.currenCavityLevel'),
      field: 'cavityLevel',
      type: LineChartType.line,
      date: 'timestamp',
      unit: '%',
      opposite: true,
      decimals: 2,
      color: SeriesColorEnum.blue,
      bullet: true,
    },
    // { // @TODO: apply the dynamic value after first release
    //   name: t('view.crusher.trendings.pid.legend.currentCrusherSP'),
    //   field: 'currentCrusherSP',
    //   type: LineChartType.line,
    //   date: 'timestamp',
    //   unit: 'A',
    //   color: 'yellow',
    //   bullet: true,
    // },
    // { // @TODO: apply the dynamic after first release
    //   name: t('view.crusher.trendings.pid.legend.cavityLevelSP'),
    //   field: 'cavityLevelSP',
    //   type: LineChartType.line,
    //   date: 'timestamp',
    //   unit: '%',
    //   opposite: true,
    //   color: 'orange',
    //   bullet: true,
    // },
    // {
    //   name: t('view.crusher.trendings.pid.legend.feedSpeed'),
    //   field: 'feedSpeed',
    //   type: LineChartType.line,
    //   date: 'timestamp',
    //   unit: '%',
    //   opposite: true,
    //   color: 'green',
    //   bullet: true,
    // },
  ];

  const convertedEdgeStatus: (
    EquipmentUnitDataCrusher | { edgeStatus: number, timestamp: number }
  )[] = operationalData.map((item: EquipmentUnitDataCrusher | EdgeDeviceOperationalStatus) => (
    'edgeStatus' in item
      ? { ...item, edgeStatus: item.edgeStatus === 'on' ? 1 : 0 }
      : item));

  return (
    <LineChart
      activeBullet
      className={className}
      data={convertedEdgeStatus}
      id="PID-trending"
      series={series}
      testId={testId}
      timeUnit="second"
    />
  );
}

export { TrendingsPID };

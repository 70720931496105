// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import { formatNumber, roundNumber } from '../../../../../plugins/general';
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  vibrationSensorToTagType,
  vibrationSensorToText,
} from '../../../../../plugins/crusher';

// Components
import { Col } from '../../../../../layout/elements/Col';
import { ModuleHeader } from '../../../../../layout/elements/ModuleHeader';
import { Tag } from '../../../../../layout/elements/Tag';
import { Row } from '../../../../../layout/elements/Row';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import { EdgeDeviceOperationalStatus } from '../../../../../types/global';
import { Theme } from '../../../../../types/theme';

// View
import {
  VibrationContainer,
  VibrationItem,
  VibrationItemsContainer,
} from './VibrationModule.style';
import { CrusherVibrationModuleProps } from './VibrationModule.types';

function VibrationModule({
  className,
  onOpenDrawer,
  operationalData,
  testId,
}: CrusherVibrationModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  const renderSensorItem = (
    sensorName: string,
    sensorValue: number | null,
    index: number,
  ): JSX.Element => (
    <VibrationItem key={sensorName}>
      <Row>
        <Col cols={11}>
          <Paragraph
            size={ParagraphSize.sm}
            weight={ParagraphWeight.medium}
            color={theme.color.crusherDashboard.vibrationModule.nameTextColor}
          >
            {`${t('view.crusher.vibration.sensor')} ${index + 1}`}
          </Paragraph>
        </Col>
        <Col cols={9}>
          <Paragraph
            size={ParagraphSize.sm}
            weight={ParagraphWeight.medium}
            color={theme.color.crusherDashboard.vibrationModule.valueTextColor}
          >
            {`${formatNumber(sensorValue)}%`}
          </Paragraph>
        </Col>
        <Col cols={4}>
          <Tag
            text={vibrationSensorToText(sensorValue, { highWarning: 55 })}
            type={vibrationSensorToTagType(
              sensorValue,
              { highWarning: 55 },
              lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
            )}
          />
        </Col>
      </Row>
    </VibrationItem>
  );

  return (
    <VibrationContainer
      data-testid={testId}
      className={className}
    >
      <Row>
        <Col>
          <ModuleHeader
            onClick={onOpenDrawer}
            title={t('view.crusher.vibration.title')}
          />
        </Col>
      </Row>
      <VibrationItemsContainer>
        {renderSensorItem(
          'vibrationSensor1',
          roundNumber(lastUnitData?.vibrationSensor1),
          0,
        )}
        {renderSensorItem(
          'vibrationSensor2',
          roundNumber(lastUnitData?.vibrationSensor2),
          1,
        )}
        {renderSensorItem(
          'vibrationSensor3',
          roundNumber(lastUnitData?.vibrationSensor3),
          2,
        )}
      </VibrationItemsContainer>
    </VibrationContainer>
  );
}

export { VibrationModule };

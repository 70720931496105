// Core
import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  height: calc(50vh - 170px);
  flex: 1;
  display: flex;
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.color.settings.moduleBackgroundColor};
  border-radius: 8px;
  margin-left: 40px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.settings.unitSystemContentBorderColor};
`;

export const ActionsContainer = styled.div`
  padding: 24px 0 24px 24px;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

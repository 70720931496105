/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

/*
  Container for the entire component. Aligns children in the center both vertically and horizontally
  and arranges them in a column.
*/
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ant-progress-circle {
    position: relative;
  };
`;

/*
  Wrapper for the content to maintain relative positioning.
*/
export const ContentContainer = styled.div`
  position: relative;
`;

/*
  Speedometer effect overlay with an absolute position and gradient background.
*/
export const SpeedometerEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 120px;
  width: 20px;
  background: linear-gradient(
    to top,
    ${({ theme }) => theme.color.speedometer.effectBackgroundColor} 10%, transparent 50%
  );
`;

/*
  Container for displaying the data value, centered horizontally.
*/
export const DataValue = styled.div`
  display: flex;
  justify-content: center;
`;

/*
  Container for displaying the unit type, centered horizontally.
*/
export const UnitType = styled.div`
  display: flex;
  justify-content: center;
`;

/*
  Container for the title, centered horizontally with padding on top.
*/
export const TitleContainer = styled.div`
  text-align: center;
  padding-top: 10px;
  display: flex;
  justify-content: center;
`;

/*
  Container for the minimum value, positioned absolutely at the bottom left.
*/
export const MinValueContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 4px;
  z-index: 1;
`;

/*
  Tick mark for the minimum value, rotated to align correctly.
*/
export const MinValueTick = styled.div`
  position: absolute;
  width: 2px; 
  height: 8px; 
  background-color: ${({ theme }) => theme.color.speedometer.ticksBackgroundColor};
  transform: rotate(-125deg);
`;

/*
  Numerical display for the minimum value, positioned absolutely below the tick.
*/
export const MinValueNumber = styled.div`
  position: absolute;
  bottom: -25px;
  right: 7px;
  width: 10px;
`;

/*
  Container for the maximum value, positioned absolutely at the bottom right.
*/
export const MaxValueContainer = styled.div`
  position: absolute;
  right: 6px;
  bottom: 20px;
  z-index: 1;
`;

/*
  Tick mark for the maximum value, rotated to align correctly.
*/
export const MaxValueTick = styled.div`
  position: absolute;
  width: 2px; 
  height: 8px;
  background-color: ${({ theme }) => theme.color.speedometer.ticksBackgroundColor};
  transform: rotate(125deg);
`;

/*
  Numerical display for the maximum value, positioned absolutely below the tick.
*/
export const MaxValueNumber = styled.div`
  position: absolute;
  height: max-content;
  bottom: -25px;
  left: 7px;
  width: 10px;
`;

// Core
import React from 'react';

export enum ModalSize {
  sm = 'sm',
  lg = 'lg',
}

export interface ModalPanelProps {
  children: React.ReactNode;
  className?: string;
  description?: string;
  onClose: () => void;
  showModal: boolean;
  size?: ModalSize;
  testId?: string;
  title: string;
}

// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  overallStatusToTagType,
  overallStatusToText,
} from '../../../../../plugins/crusher';
import { convertPressure } from '../../../../../plugins/general';

// Store
import {
  getMeasurementUnit,
  MeasurementType,
  PressureType,
} from '../../../../../store/measurement.store';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';
import { Theme } from '../../../../../types/theme';

// Components
import { Col } from '../../../../../layout/elements/Col';
import { ModuleHeader } from '../../../../../layout/elements/ModuleHeader';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../layout/elements/Row';
import { Speedometer } from '../../../../../layout/elements/Speedometer';
import { Tag } from '../../../../../layout/elements/Tag';

// View
import { CrusherHydraulicModuleProps } from './HydraulicModule.types';
import {
  ClampPressureContainer,
  HydraulicContainer,
  OverallStatusContainer,
  SpeedometersContainer,
  TankTempStatusContainer,
  TrampPressureContainer,
} from './HydraulicModule.style';

function HydraulicModule({
  className,
  onOpenDrawer,
  operationalData,
  testId,
}: CrusherHydraulicModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);
  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  const pressureUnit: PressureType = getMeasurementUnit(MeasurementType.pressure);

  return (
    <HydraulicContainer
      data-testid={testId}
      className={className}
    >
      <Row>
        <Col>
          <ModuleHeader
            onClick={onOpenDrawer}
            title={t('view.crusher.hydraulic.title')}
          />
        </Col>
      </Row>
      <Row>
        <Col cols={12}>
          <OverallStatusContainer>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              color={theme.color.crusherDashboard.statusTitleColor}
            >
              {t('view.crusher.overallStatus')}
            </Paragraph>
            <Tag
              text={overallStatusToText(lastUnitData?.hydraulicOverallStatus)}
              type={overallStatusToTagType(
                lastUnitData?.hydraulicOverallStatus,
                lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
              )}
            />
          </OverallStatusContainer>
        </Col>
        <Col cols={12}>
          <TankTempStatusContainer>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              color={theme.color.crusherDashboard.statusTitleColor}
            >
              {t('view.crusher.hydraulic.tankTemp')}
            </Paragraph>
            <Tag
              text={overallStatusToText(lastUnitData?.hydraulicTankTempStatus)}
              type={overallStatusToTagType(
                lastUnitData?.hydraulicTankTempStatus,
                lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
              )}
            />
          </TankTempStatusContainer>
        </Col>
      </Row>
      <Row style={{ flex: 1 }}>
        <SpeedometersContainer>
          <ClampPressureContainer>
            <Col cols={12}>
              <Speedometer
                minValue={
                  convertPressure(lastUnitData?.spClampPressureScaleMin, pressureUnit)
                }
                maxValue={
                  convertPressure(lastUnitData?.spClampPressureScaleMax, pressureUnit)
                }
                value={
                  convertPressure(lastUnitData?.hydraulicClampPressure, pressureUnit)
                }
                unit={pressureUnit}
                thresholds={{
                  lowAlarm: convertPressure(
                    lastUnitData?.spClampPressureLowAlarm,
                    pressureUnit,
                  ),
                  lowWarning: convertPressure(
                    lastUnitData?.spClampPressureStartPressurize,
                    pressureUnit,
                  ),
                  highWarning: convertPressure(
                    lastUnitData?.spClampPressureStopPressurize,
                    pressureUnit,
                  ),
                }}
                title={`${t('view.crusher.hydraulic.clampPressure')}`}
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
              />
            </Col>
          </ClampPressureContainer>

          <TrampPressureContainer>
            <Col cols={12}>
              <Speedometer
                minValue={
                  convertPressure(lastUnitData?.spTrampPressureScaleMin, pressureUnit)
                }
                maxValue={
                  convertPressure(lastUnitData?.spTrampPressureScaleMax, pressureUnit)
                }
                value={
                  convertPressure(lastUnitData?.hydraulicTrampPressure, pressureUnit)
                }
                unit={pressureUnit}
                thresholds={{
                  lowAlarm: convertPressure(
                    lastUnitData?.spTrampPressureLowAlarm,
                    pressureUnit,
                  ),
                  lowWarning: convertPressure(
                    lastUnitData?.spTrampPressureStartPressurize,
                    pressureUnit,
                  ),
                  highWarning: convertPressure(
                    lastUnitData?.spTrampPressureStopPressurize,
                    pressureUnit,
                  ),
                }}
                title={`${t('view.crusher.hydraulic.trampPressure')}`}
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
              />
            </Col>
          </TrampPressureContainer>
        </SpeedometersContainer>
      </Row>
    </HydraulicContainer>
  );
}

export { HydraulicModule };

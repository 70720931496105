// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  overallStatusToTagType,
  overallStatusToText,
  runningStatusToTagType,
  runningStatusToText,
  weatherModeStatusToTagType,
  weatherModeStatusToText,
} from '../../../../../plugins/crusher';
import { convertPressure, convertTemperature } from '../../../../../plugins/general';

// Store
import {
  getMeasurementUnit,
  MeasurementType,
  PressureType,
  TemperatureType,
} from '../../../../../store/measurement.store';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';
import { Theme } from '../../../../../types/theme';

// Components
import { Col } from '../../../../../layout/elements/Col';
import { ModuleHeader } from '../../../../../layout/elements/ModuleHeader';
import { Row } from '../../../../../layout/elements/Row';
import { Speedometer } from '../../../../../layout/elements/Speedometer';
import { Tag } from '../../../../../layout/elements/Tag';
import { Thermometer } from '../../../../../layout/elements/Thermometer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';

// View
import {
  CoolerFanStatusContainer,
  LubePressureStatusContainer,
  LubricationContainer,
  LubricationContent,
  OverallStatusContainer,
  ThermometersContainer,
  WinterModeStatusContainer,
} from './LubricationModule.style';
import { CrusherLubricationModuleProps } from './LubricationModule.types';

function LubricationModule({
  className,
  onOpenDrawer,
  operationalData,
  testId,
}: CrusherLubricationModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);
  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  const userPressureUnit: PressureType = getMeasurementUnit(MeasurementType.pressure);
  const userTemperatureUnit: TemperatureType = getMeasurementUnit(MeasurementType.temperature);

  return (
    <LubricationContainer
      data-testid={testId}
      className={className}
    >
      <LubricationContent>
        <Row>
          <Col>
            <ModuleHeader onClick={onOpenDrawer} title={t('view.crusher.lubrication.title')} />
          </Col>
        </Row>
        <Row>
          <Col cols={8}>
            <OverallStatusContainer>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.medium}
                color={theme.color.crusherDashboard.statusTitleColor}
              >
                {t('view.crusher.overallStatus')}
              </Paragraph>
              <Tag
                text={overallStatusToText(lastUnitData?.lubricationOverallStatus)}
                type={overallStatusToTagType(
                  lastUnitData?.lubricationOverallStatus,
                  lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                )}
              />
            </OverallStatusContainer>
          </Col>
          <Col cols={8}>
            <WinterModeStatusContainer>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.medium}
                color={theme.color.crusherDashboard.statusTitleColor}
              >
                {t('view.crusher.lubrication.winterMode')}
              </Paragraph>
              <Tag
                text={weatherModeStatusToText(lastUnitData?.weatherModeStatus)}
                type={weatherModeStatusToTagType(
                  lastUnitData?.weatherModeStatus,
                  lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                )}
                className="status"
              />
            </WinterModeStatusContainer>
          </Col>
          <Col cols={8}>
            <CoolerFanStatusContainer>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.medium}
                color={theme.color.crusherDashboard.statusTitleColor}
              >
                {t('view.crusher.lubrication.coolerFan')}
              </Paragraph>
              <Tag
                text={runningStatusToText(lastUnitData?.coolerFanStatus)}
                type={runningStatusToTagType(
                  lastUnitData?.coolerFanStatus,
                  lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                )}
                className="status"
              />
            </CoolerFanStatusContainer>
          </Col>
        </Row>

        <Row style={{ flex: 1 }}>
          <Col cols={16}>
            <ThermometersContainer>
              <Row>
                <Col cols={8}>
                  <Thermometer
                    className="thermometer"
                    maxValue={convertTemperature(
                      lastUnitData?.spReturnTempScaleMax,
                      userTemperatureUnit,
                    )}
                    minValue={convertTemperature(
                      lastUnitData?.spReturnTempScaleMin,
                      userTemperatureUnit,
                    )}
                    value={convertTemperature(
                      lastUnitData?.lubricationReturnTemperature,
                      userTemperatureUnit,
                    )}
                    unit={userTemperatureUnit}
                    thresholds={{
                      lowAlarm: convertTemperature(
                        lastUnitData?.spLubeReturnTempLowAlarm,
                        userTemperatureUnit,
                      ),
                      lowWarning: convertTemperature(
                        lastUnitData?.spLubeReturnTempLowWarning,
                        userTemperatureUnit,
                      ),
                      highWarning: convertTemperature(
                        lastUnitData?.spLubeReturnTempHighWarn,
                        userTemperatureUnit,
                      ),
                    }}
                    title={`${t('view.crusher.lubrication.returnTemp')}`}
                    running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  />
                </Col>
                <Col cols={8}>
                  <Thermometer
                    className="thermometer"
                    maxValue={convertTemperature(
                      lastUnitData?.spTankTempScaleMax,
                      userTemperatureUnit,
                    )}
                    minValue={convertTemperature(
                      lastUnitData?.spTankTempScaleMin,
                      userTemperatureUnit,
                    )}
                    value={convertTemperature(
                      lastUnitData?.lubricationTankTemperature,
                      userTemperatureUnit,
                    )}
                    unit={userTemperatureUnit}
                    thresholds={{
                      lowAlarm: convertTemperature(
                        lastUnitData?.spLubeTankTempLowAlarm,
                        userTemperatureUnit,
                      ),
                      lowWarning: convertTemperature(
                        lastUnitData?.spLubeTankTempLowWarning,
                        userTemperatureUnit,
                      ),
                      highWarning: convertTemperature(
                        lastUnitData?.spLubeTankTempHighWarning,
                        userTemperatureUnit,
                      ),
                      highAlarm: convertTemperature(
                        lastUnitData?.spLubeTankTempHighAlarm,
                        userTemperatureUnit,
                      ),
                    }}
                    title={`${t('view.crusher.lubrication.tankTemp')}`}
                    running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  />
                </Col>
                <Col cols={8}>
                  <Thermometer
                    className="thermometer"
                    minValue={convertTemperature(
                      lastUnitData?.spSupplyTempScaleMin,
                      userTemperatureUnit,
                    )}
                    maxValue={convertTemperature(
                      lastUnitData?.spSupplyTempScaleMax,
                      userTemperatureUnit,
                    )}
                    value={convertTemperature(
                      lastUnitData?.lubricationSupplyTemperature,
                      userTemperatureUnit,
                    )}
                    unit={userTemperatureUnit}
                    thresholds={{
                      lowWarning: convertTemperature(
                        lastUnitData?.spLubeSupplyTempLowWarn,
                        userTemperatureUnit,
                      ),
                    }}
                    title={`${t('view.crusher.lubrication.supplyTemp')}`}
                    running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  />
                </Col>
              </Row>
            </ThermometersContainer>
          </Col>

          <Col cols={8}>
            <LubePressureStatusContainer>
              <Speedometer
                className="speedometer"
                minValue={
                  convertPressure(lastUnitData?.spSupplyPressureScaleMin, userPressureUnit)
                }
                maxValue={
                  convertPressure(lastUnitData?.spSupplyPressureScaleMax, userPressureUnit)
                }
                value={
                  convertPressure(lastUnitData?.lubricationOilPressure, userPressureUnit)
                }
                unit={userPressureUnit}
                thresholds={{
                  lowAlarm: convertPressure(
                    lastUnitData?.spLubePressureLowAlarm,
                    userPressureUnit,
                  ),
                  lowWarning: convertPressure(
                    lastUnitData?.spLubePressureLowWarning,
                    userPressureUnit,
                  ),
                }}
                title={`${t('view.crusher.lubrication.lubePressure')}`}
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
              />
            </LubePressureStatusContainer>
          </Col>
        </Row>

      </LubricationContent>
    </LubricationContainer>
  );
}

export { LubricationModule };

// Core
import styled from 'styled-components';

export const PanelContainer = styled.div`
  background-color: ${({ theme }) => theme.color.settings.panelBackgroundColor};
  height: calc(100vh - 64px);  
`;

export const PageHeaderContainer = styled.div`
  padding: 40px;
`;

export const RegisteredTrademark = styled.sup`
  font-size: 13px;
`;

/* ************** PROFILE SETTINGS ******************* */

export const ProfileSettingsContainer = styled.div`
  height: 100%;
`;

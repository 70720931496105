/* eslint-disable @typescript-eslint/indent */
/*
   Note: This Thermometer component has a lot of the styling inverted due to its vertical
   orientation and use of rotation transforms.
   Be sure to carefully review any changes to the styling of this component to avoid unexpected
   effects and ensure visual consistency.
*/

// Core
import styled from 'styled-components';

// Libraries
import { Progress as ProgressAntD } from 'antd';
import _ from 'lodash';

// Plugins
import { formatNumber } from '../../../plugins/general';

// Types
import { Theme, ThemedProps } from '../../../types/theme';
import { Thresholds } from '../../../types/global';

// Component
import { ThermometerProps } from './Thermometer.types';

/*
  Changes the text color based on the threshold values
  @param theme receives the theme object
  @param value receives the current thermometer value
  @param thresholds receives the threshold object
  @param running indicates if the thermometer is running
  @returns a string representing the appropriate color
*/
const getTextColorByThresholds = (
  theme: Theme,
  value: ThermometerProps['value'],
  thresholds: ThermometerProps['thresholds'],
  running: ThermometerProps['running'],
): string => {
  let color: string = 'inherit';
  if (!running || _.isNil(value)) {
    color = theme.color.thermometer.offProgressBackgroundColor;
  } else if ((thresholds?.highAlarm && value >= thresholds?.highAlarm)
    || (thresholds?.lowAlarm && value <= thresholds?.lowAlarm)) {
    color = theme.color.thermometer.alarmTextColor;
  } else if ((thresholds?.highWarning && value >= thresholds?.highWarning)
    || (thresholds?.lowWarning && value <= thresholds?.lowWarning)) {
    color = theme.color.thermometer.warningTextColor;
  }
  return color;
};

/*
  Generates CSS styles to display threshold ticks on the thermometer
  @param threshold receives a record of threshold values
  @param theme receives the theme object
  @param running indicates if the thermometer is running
  @returns a string of CSS styles
*/
const showThresholdTicks = (
  threshold: Thresholds,
  theme: Theme,
  running: boolean,
): string => {
  const mapStepColor = (formatedThreshold: string, backgroundColor: string): string => `
    .ant-progress-steps-item:nth-child(${formatedThreshold}) {
      height: 12px !important;
      width: 2px !important;
      background-color: ${
        running
          ? backgroundColor
          : theme.color.thermometer.offThresholdTickBackgroundColor
      };
    };
  `;

  return `
    ${mapStepColor(
      formatNumber(threshold.highWarning),
      theme.color.thermometer.tickHighWarningBackgroundColor,
    )}
    ${mapStepColor(
      formatNumber(threshold.highAlarm),
      theme.color.thermometer.tickHighAlarmBackgroundColor,
    )}
    ${mapStepColor(
      formatNumber(threshold.lowWarning),
      theme.color.thermometer.tickLowWarningBackgroundColor,
    )}
    ${mapStepColor(
      formatNumber(threshold.lowAlarm),
      theme.color.thermometer.tickLowAlarmBackgroundColor,
    )}
  `;
};

/*
  Styled component for the Thermometer element
  @param value receives the current thermometer value
  @param thresholds receives the threshold object
  @param running indicates if the thermometer is running
  @param theme receives the theme object
*/
export const Element = styled.div<Pick<
  ThermometerProps, 'value' | 'thresholds' | 'running'> & ThemedProps
>`
  height: 210px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  /* ******* VALUES STYLES ******* */
  .max-value-container,
  .min-value-container {
    font-family: ${({ theme }) => theme.font.regular};
    color: ${({ theme }) => theme.color.thermometer.minAndMaxValueTextColor};
    transform: rotate(90deg);
    margin-top: 16px;
    font-size: 12px;
    width: 10px;
    height: 10px;
  };

  /* ******* VALUE CONTAINER ******* */
  .value-container {
    transform: rotate(90deg);
    margin-top: 8px;
    font-size: 18px;
    white-space: nowrap;
    color: ${({
      theme,
      value,
      thresholds,
      running,
    }) => getTextColorByThresholds(
      theme,
      value,
      thresholds,
      running,
    )};
  };
`;

/* Styled component for the container of the Thermometer */
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  height: 150px;
  position: relative;
`;

/* Styled component for the container of progress indicators */
export const ProgressIndicatorsContainer = styled.div`
  transform: rotate(270deg);
`;

/* Styled component for the AntD progress bar */
export const ThermometerAntD = styled(ProgressAntD)`
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;

  /* ******* PROGRESS ******* */
  .ant-progress-small {
    width: 10px;
  };

  /* ******* PROGRESS STEPS ******* */
  .ant-progress-steps-item {
    width: 1px;
  };
`;

/*
  Styled component for the steps of the thermometer
  @param threshold receives a record of threshold values
  @param running indicates if the thermometer is running
*/
export const StepsThermometer = styled.div<{ threshold: Thresholds, running: boolean }>`
  display: flex;
  position: absolute;
  top: 20px;
  left: 0;
  bottom: 0;
  right: 0;

  /* ******* PROGRESS STEPS ITEM ******* */
  .ant-progress-steps-item {
    position: relative;

    span {
      position: absolute;
      top: 12px;
      right: 0;
      font-size: 10px;
      transform: rotate(90deg);
    };
  };

  ${({ threshold, theme, running }) => showThresholdTicks(threshold, theme, running)};

  /* ******* PROGRESS STEPS OUTER ******* */
  .ant-progress-steps-outer {
    align-items: start;
  };
`;

/* Styled component for the progress thermometer */
export const ProgressThermometer = styled.div`
  width: 170px;
  height: 60px;
`;

/* Styled component for the text container */
export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Core
import React from 'react';

// Component
import { ModalPanelProps, ModalSize } from './ModalPanel.types';
import {
  Container,
  Element,
  Header,
} from './ModalPanel.style';

// Layout
import { Button, ButtonSize, ButtonType } from '../Button';
import { Col } from '../Col';
import { Row } from '../Row';
import { Close } from '../../icons';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../Paragraph';

function ModalPanel({
  children,
  className,
  description,
  onClose,
  showModal = false,
  size = ModalSize.lg,
  testId,
  title,
}: ModalPanelProps): JSX.Element {
  const computedWidthBySize: '40%' | '85%' = size === ModalSize.sm ? '40%' : '85%';

  return (
    <Container
      centered
      className={className}
      closeIcon={false}
      data-testid={testId}
      footer={false}
      onCancel={onClose}
      open={showModal}
      size={size}
      width={computedWidthBySize}
    >
      <Row>
        <Header description={description}>
          <Paragraph
            className="modal-title"
            size={ParagraphSize.xl}
            weight={ParagraphWeight.bold}
          >
            {title}
          </Paragraph>
          <Button
            type={ButtonType.secondary}
            square
            size={ButtonSize.sm}
            onClick={onClose}
            className="close-trendings-button"
          >
            <Close />
          </Button>
        </Header>
        <Row>
          <Col>
            <Paragraph className="modal-header-description">
              {description}
            </Paragraph>
          </Col>
        </Row>
      </Row>
      <Element>
        <Col>
          {children}
        </Col>
      </Element>
    </Container>
  );
}

export { ModalPanel };

// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  convertTemperature,
  formatNumber,
  roundNumber,
} from '../../../../../../plugins/general';
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  overallStatusToTagType,
  overallStatusToText,
  permissiveAlarmToStatusTag,
  permissiveStatusToAlarmIndicatorStatus,
  permissiveStatusToAlarmTagType,
  permissiveStatusToIndicatorStatus,
  vibrationSensorToTagType,
  vibrationSensorToText,
} from '../../../../../../plugins/crusher';

// Store
import {
  getMeasurementUnit,
  MeasurementType,
  TemperatureType,
} from '../../../../../../store/measurement.store';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../../types/global';
import { Theme } from '../../../../../../types/theme';

// Components
import { AlertType } from '../../../../../../layout/elements/Alert';
import { Button, ButtonType } from '../../../../../../layout/elements/Button';
import { Col } from '../../../../../../layout/elements/Col';
import { Drawer, DrawerSize } from '../../../../../../layout/elements/Drawer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../../layout/elements/Row';
import { Speedometer } from '../../../../../../layout/elements/Speedometer';
import {
  StatusIndicator,
  StatusIndicatorSize,
} from '../../../../../../layout/elements/StatusIndicator';
import { Tag } from '../../../../../../layout/elements/Tag';
import { Thermometer } from '../../../../../../layout/elements/Thermometer';
import { AlertLine } from '../../../../../../layout/icons';

// Component
import { MotorStatusProps } from './MotorStatus.types';
import {
  CounterShaftBushingStatusContainer,
  CounterShaftVibrationTitle,
  CrusherStartListDescription,
  FooterContainer,
  MotorCurrentSpeedometer,
  OverallContainer,
  OverallPermissiveContainer,
  OverallStatusContainer,
  RPMMotorSpeedometer,
  RingVibrationContainer,
  SpeedometersContainer,
  ThermometersAndSensorsContainer,
  ThermometersContainer,
  VibrationSensorItem,
} from './MotorStatus.style';

function MotorStatus({
  className,
  isDrawerOpen,
  onCloseDrawer,
  operationalData,
  testId,
}: MotorStatusProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);
  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  const userTemperatureUnit: TemperatureType = getMeasurementUnit(MeasurementType.temperature);

  const renderSensorItem = (
    name: string,
    value: number | null,
    thresholds?: number | null,
  ): JSX.Element => (
    <VibrationSensorItem>
      <Row>
        <Col cols={11}>
          <Paragraph
            size={ParagraphSize.sm}
            weight={ParagraphWeight.medium}
            color={theme.color.crusherDashboard.motorStatus.sensorListTextColor}
          >
            {`${t('common.sensor')} ${name.replace('vibrationSensor', '')}`}
          </Paragraph>
        </Col>
        <Col cols={9}>
          <Paragraph
            size={ParagraphSize.sm}
            weight={ParagraphWeight.medium}
            color={theme.color.crusherDashboard.motorStatus.sensorListValuePercentageTextColor}
          >
            {`${formatNumber(value)}%`}
          </Paragraph>
        </Col>
        <Col cols={4}>
          <Tag
            text={vibrationSensorToText(value, { highWarning: thresholds })}
            type={
              vibrationSensorToTagType(
                value,
                { highWarning: thresholds },
                lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
              )
            }
          />
        </Col>
      </Row>
    </VibrationSensorItem>
  );

  const renderPermissiveItem = (
    permissiveName: string,
    permissiveValue: boolean | null | undefined,
  ): JSX.Element => (
    <Col cols={12}>
      <CrusherStartListDescription
        status={
          permissiveStatusToIndicatorStatus(
            permissiveValue,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )
        }
      >
        <StatusIndicator
          className="list-text-description"
          text={t(`view.crusher.motorStatus.drawer.${permissiveName}`)}
          status={
            permissiveStatusToIndicatorStatus(
              permissiveValue,
              lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
            )
          }
        />
      </CrusherStartListDescription>
    </Col>
  );

  // Inverted Permissive Item (false = red, true = green)
  const renderInvertedPermissiveItem = (
    permissiveName: string,
    permissiveValue: boolean | null | undefined,
  ): JSX.Element => (
    <Col cols={12}>
      <CrusherStartListDescription
        status={
            permissiveStatusToAlarmIndicatorStatus(
              permissiveValue,
              lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
            )
          }
      >
        <StatusIndicator
          className="list-text-description"
          text={t(`view.crusher.motorStatus.drawer.${permissiveName}`)}
          status={
              permissiveStatusToAlarmIndicatorStatus(
                permissiveValue,
                lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
              )
            }
        />
      </CrusherStartListDescription>
    </Col>
  );

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t('view.crusher.motorStatus.drawer.title')}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      footer={false}
      alert={
        lastEdgeStatus?.edgeStatus !== EdgeDeviceOperationalStatusStatus.on
        && {
          type: AlertType.warning,
          icon: <AlertLine />,
          description: `${t('layout.elements.drawer.alertText')}`,
        }
      }
      size={DrawerSize.lg}
    >
      <OverallContainer>
        <Row>
          <Col>
            <OverallStatusContainer>
              <Paragraph weight={ParagraphWeight.bold}>
                {t('view.crusher.overallStatus')}
              </Paragraph>
              <Tag
                text={overallStatusToText(lastUnitData?.crusherMotorOverallStatus)}
                type={
                  overallStatusToTagType(
                    lastUnitData?.crusherMotorOverallStatus,
                    lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                  )
                }
              />
            </OverallStatusContainer>
          </Col>
        </Row>
        {/* Motor RPM and Motor Amps */}
        <Row>
          <Col>
            <SpeedometersContainer>
              <Row>
                <Col cols={12}>
                  <RPMMotorSpeedometer>
                    <Speedometer
                      running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                      title={`${t('view.crusher.motorStatus.drawer.motorRpm')}`}
                      minValue={lastUnitData?.spCrusherMotorRpmScaleMin}
                      maxValue={lastUnitData?.spCrusherMotorRpmScaleMax}
                      value={lastUnitData?.crusherMotorRpm}
                      unit="rpm"
                      thresholds={{}}
                    />
                  </RPMMotorSpeedometer>
                </Col>
                <Col cols={12}>
                  <MotorCurrentSpeedometer>
                    <Speedometer
                      running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                      title={`${t('view.crusher.motorStatus.drawer.motorAmps')}`}
                      minValue={lastUnitData?.spCrusherMotorAmpsScaleMin}
                      maxValue={lastUnitData?.spCrusherMotorAmpsScaleMax}
                      value={lastUnitData?.crusherMotorAmps}
                      unit="A"
                      thresholds={{
                        highWarning: lastUnitData?.spCrusherMotorCrushingAmps,
                        highAlarm: lastUnitData?.spCrusherMotorFullLoadAmps,
                        lowWarning: lastUnitData?.spCrusherMotorRunningAmps,
                      }}
                    />
                  </MotorCurrentSpeedometer>
                </Col>
              </Row>
            </SpeedometersContainer>
          </Col>
        </Row>
        {/* Title and Status of Counter Shaft Bushing */}
        <Row>
          <Col>
            <CounterShaftVibrationTitle>
              <Row>
                <Col cols={12}>
                  {/* This tag has inverted logic of true and false (true = good, false = fault) */}
                  <CounterShaftBushingStatusContainer>
                    <Paragraph weight={ParagraphWeight.bold}>
                      {t('view.crusher.motorStatus.drawer.countershaftBushingStatus')}
                    </Paragraph>
                    <Tag
                      text={permissiveAlarmToStatusTag(
                        lastUnitData?.countershaftBushingTransducerFault,
                      )}
                      type={
                        permissiveStatusToAlarmTagType(
                          lastUnitData?.countershaftBushingTransducerFault,
                          lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                        )
                      }
                    />
                  </CounterShaftBushingStatusContainer>
                </Col>
                <Col cols={12}>
                  <RingVibrationContainer>
                    <Paragraph weight={ParagraphWeight.bold}>
                      {t('view.crusher.motorStatus.drawer.ringVibration')}
                    </Paragraph>
                  </RingVibrationContainer>
                </Col>
              </Row>
            </CounterShaftVibrationTitle>
          </Col>
        </Row>
        {/* Thermometers and sensors */}
        <Row>
          <Col>
            <ThermometersAndSensorsContainer>
              <Row>
                <Col cols={12}>
                  <ThermometersContainer>
                    <Row>
                      <Col cols={12}>
                        <Thermometer
                          running={
                            lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on
                          }
                          minValue={convertTemperature(
                            lastUnitData?.spCounterShaftBushingScaleMin,
                            userTemperatureUnit,
                          )}
                          maxValue={convertTemperature(
                            lastUnitData?.spCounterShaftBushingScaleMax,
                            userTemperatureUnit,
                          )}
                          value={convertTemperature(
                            lastUnitData?.countershaftBushingTemp,
                            userTemperatureUnit,
                          )}
                          unit={userTemperatureUnit}
                          title={`${t('view.crusher.motorStatus.drawer.countershaftBushingTemp')}`}
                        />
                      </Col>
                      <Col cols={12}>
                        <Thermometer
                          running={
                            lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on
                          }
                          minValue={convertTemperature(
                            lastUnitData?.spEccentricBushingScaleMin,
                            userTemperatureUnit,
                          )}
                          maxValue={convertTemperature(
                            lastUnitData?.spEccentricBushingScaleMax,
                            userTemperatureUnit,
                          )}
                          value={convertTemperature(
                            lastUnitData?.countershaftEccentricTemp,
                            userTemperatureUnit,
                          )}
                          unit={userTemperatureUnit}
                          thresholds={{
                            highWarning: convertTemperature(
                              lastUnitData?.spEccentricBushingTempHighStopFeed,
                              userTemperatureUnit,
                            ),
                            highAlarm: convertTemperature(
                              lastUnitData?.spEccentricBushingTempHighStopCrusher,
                              userTemperatureUnit,
                            ),
                            lowWarning: convertTemperature(
                              lastUnitData?.spEccentricBushingTempHigh,
                              userTemperatureUnit,
                            ),
                          }}
                          title={`${t('view.crusher.motorStatus.drawer.countershaftEccentricTemp')}`}
                        />
                      </Col>
                    </Row>
                  </ThermometersContainer>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }} cols={12}>
                  {
                    renderSensorItem(
                      'vibrationSensor1',
                      roundNumber(lastUnitData?.vibrationSensor1),
                      lastUnitData?.spVibration1Warning,
                    )
                  }
                  {
                    renderSensorItem(
                      'vibrationSensor2',
                      roundNumber(lastUnitData?.vibrationSensor2),
                      lastUnitData?.spVibration2Warning,
                    )
                  }
                  {
                    renderSensorItem(
                      'vibrationSensor3',
                      roundNumber(lastUnitData?.vibrationSensor3),
                      lastUnitData?.spVibration3Warning,
                    )
                  }
                  {/* @TODO algumas máquinas tem o sensor 4 (P500) */}
                </Col>
              </Row>
            </ThermometersAndSensorsContainer>
          </Col>
        </Row>
        {/* Permissives */}
        <Row>
          <Col>
            <OverallPermissiveContainer>
              <StatusIndicator
                text={t('view.crusher.motorStatus.drawer.crusherStart')}
                status={
                  permissiveStatusToIndicatorStatus(
                    lastUnitData?.crusherStartPermissives,
                    lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                  )
                }
                size={StatusIndicatorSize.lg}
              />
            </OverallPermissiveContainer>
            <Row style={{ paddingBottom: 24 }} gutter={50}>
              {renderPermissiveItem('calibrationActive', lastUnitData?.calibrationActive)}
              {renderPermissiveItem(
                'lubePressureTransducerFault',
                lastUnitData?.lubePressureTransducerFault,
              )}
              {renderPermissiveItem('lubeOilLevelLow', lastUnitData?.lubeOilLevelLow)}
              {renderPermissiveItem('lubeReturnLowTemp', lastUnitData?.lubeReturnLowTemp)}
              {renderPermissiveItem('crusherBeltSlip', lastUnitData?.crusherBeltSlip)}
              {renderPermissiveItem('crusherAboveMinimumCSS', lastUnitData?.crusherAboveMinimumCSS)}
              {renderPermissiveItem('crusherMotor2Fault', lastUnitData?.crusherMotor2Fault)}
              {renderPermissiveItem('lowLubeOilPressure', lastUnitData?.lowLubeOilPressure)}
              {renderPermissiveItem('crusherMotor1Fault', lastUnitData?.crusherMotor1Fault)}
              {renderPermissiveItem('minimumLubeRunTimer', lastUnitData?.minimumLubeRunTimer)}
              {renderPermissiveItem('lubeReturnTempHigh', lastUnitData?.lubeReturnTempHigh)}
              {renderPermissiveItem('lowLubeOil', lastUnitData?.lowLubeOil)}
              {/* This tag has inverted logic of true and false (true = green, false = red) */}
              {renderInvertedPermissiveItem(
                'countershaftBushingTransducerFault',
                lastUnitData?.countershaftBushingTransducerFault,
              )}
              {renderPermissiveItem(
                'lubeReturnTempTransducerFault',
                lastUnitData?.lubeReturnTempTransducerFault,
              )}
              {renderPermissiveItem(
                'cavityLevelTransducerFault',
                lastUnitData?.cavityLevelTransducerFault,
              )}
            </Row>
          </Col>
        </Row>
      </OverallContainer>
      {/* Coast Down History */}
      <Row>
        <FooterContainer>
          <Paragraph weight={ParagraphWeight.bold}>
            {t('view.crusher.motorStatus.drawer.coastDownHistory')}
          </Paragraph>
          <Button
            type={ButtonType.secondary}
            disabled
            block={false}
            onClick={() => {}}
          >
            {t('common.openChart')}
          </Button>
        </FooterContainer>
      </Row>
    </Drawer>
  );
}

export { MotorStatus };

// Core
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Libraries
import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  requestVantageApi,
  RequestMethod,
  isHTTPResponseError,
} from '../../plugins/request';

// Store
import {
  getUser,
  setAuthToken,
  setUser,
} from '../../store/session.store';

// Types
import {
  LoginAuthResponse,
  User,
  UserType,
} from '../../types/global';

// Layout
import { Alert, AlertType } from '../../layout/elements/Alert';
import { Button, ButtonSize } from '../../layout/elements/Button';
import { Checkbox, CheckboxSize } from '../../layout/elements/Checkbox';
import { Input, InputType } from '../../layout/elements/Input';
import { LeftPanelImageRight } from '../../layout/LeftPanelImageRight';
import {
  LinkTo,
  LinkToColor,
  LinkToType,
  LinkToWeight,
} from '../../layout/elements/LinkTo';
import { ModalPanel, ModalSize } from '../../layout/elements/ModalPanel';
import { Paragraph, ParagraphAlign } from '../../layout/elements/Paragraph';
import { showToast, ToastType } from '../../layout/elements/Toast';
import {
  Title,
  TitleAlign,
  TitleSize,
  TitleWeight,
} from '../../layout/elements/Title';
import { AlertLine } from '../../layout/icons';

// Style
import {
  AlertContainer,
  Description,
  FormContentContainer,
  InputEmail,
  LoginContentContainer,
  OptionsLoginContainer,
  RegisteredTrademark,
  TitleContainer,
  UpdatePasswordAlert,
  UpdatePasswordButtonContainer,
  UpdatePasswordContent,
} from './Login.style';

function Login(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* ***********************************************************************************************
  ***************************************** LOCAL STATES *******************************************
  *********************************************************************************************** */

  interface LoginFormState {
    email: string | null;
    error: string | null;
    password: string | null;
    rememberMe: boolean;
  }

  const initialLoginFormState: LoginFormState = {
    email: null,
    error: null,
    password: null,
    rememberMe: false,
  };

  const [loginFormState, setLoginFormState] = useState<LoginFormState>(initialLoginFormState);

  /* ************************************* UPDATE PASSWORD ************************************** */
  interface UpdatePasswordState {
    authToken: string | null;
    buttonLoading: number | null | true;
    confirmPassword: string | null;
    invalidPasswordErrorMessage: string | null;
    isTemporaryPassword: boolean;
    newPassword: string | null;
    passwordUpdated: boolean;
    showPasswordModal: boolean;
  }

  const initialUpdatePasswordState: UpdatePasswordState = {
    authToken: null,
    buttonLoading: null,
    confirmPassword: null,
    invalidPasswordErrorMessage: null,
    isTemporaryPassword: false,
    newPassword: null,
    passwordUpdated: false,
    showPasswordModal: false,
  };

  const [
    updatePasswordState,
    setUpdatePasswordState,
  ] = useState<UpdatePasswordState>(initialUpdatePasswordState);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  // Function to handle errors
  const handleLoginFormErrors = (error: unknown): void => {
    let errorMessage = t('common.defaultError');

    // Check if the error is an HTTP response error
    if (isHTTPResponseError(error)) {
      if (error.message.includes('Network Error')) {
        errorMessage = t('common.networkError');
      } else if (error.response.status === 401) {
        errorMessage = t('view.login.invalidUserOrPassword');
      }
    }

    setLoginFormState({ ...loginFormState, error: errorMessage });
  };

  /**
   * Validates the password and returns only the error messages for failing validations.
   * @param password - The password to validate
   * @param confirmPassword - The confirmation password to validate
   * @returns A string with all error messages separated by newline, or null if valid
   */
  const validatePassword = (
    password: string | null,
    confirmPassword: string | null,
  ): string | null => {
    const errors: string[] = [];

    // Only perform further validations if both fields are filled
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        errors.push(t('view.login.passwordsDoNotMatch'));
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        errors.push(t('view.login.passwordMustContainSpecialChar'));
      }
      if (!/[a-z]/.test(password)) {
        errors.push(t('view.login.passwordMustContainLowercase'));
      }
      if (!/[A-Z]/.test(password)) {
        errors.push(t('view.login.passwordMustContainUppercase'));
      }
      if (!/[0-9]/.test(password)) {
        errors.push(t('view.login.passwordMustContainNumber'));
      }
    }

    return errors.length > 0 ? errors.join('\n') : null;
  };

  /**
  * Submits the login form data to the authentication endpoint.
  *
  * @returns {Promise<void>} A promise that resolves when the login process is complete.
  */
  const submitLoginForm = async (): Promise<void> => {
    setUpdatePasswordState((prevState: UpdatePasswordState) => ({
      ...prevState,
      buttonLoading: true,
    }));

    try {
      const authResponse: LoginAuthResponse = await requestVantageApi.users({
        method: RequestMethod.POST,
        path: '/auth/authenticate',
        body: {
          email: loginFormState.email,
          password: loginFormState.password,
        },
        handleErrors: false,
      });

      const { token, temporaryPassword } = authResponse;

      setUpdatePasswordState((prevState: UpdatePasswordState) => ({
        ...prevState,
        authToken: token,
      }));

      const decodedToken: { user: string } = jwtDecode(token);
      const user: User = JSON.parse(decodedToken.user);
      setUser(user);

      if (temporaryPassword === false) {
        setAuthToken(token);

        if (user?.type === UserType.admin) {
          navigate('/');
        } else if (user?.type === UserType.customer) {
          navigate('/plant-management');
        }
      } else {
        setUpdatePasswordState((prevState: UpdatePasswordState) => ({
          ...prevState,
          showPasswordModal: true,
          buttonLoading: null,
        }));
      }
    } catch (error: unknown) {
      setUpdatePasswordState((prevState: UpdatePasswordState) => ({
        ...prevState,
        buttonLoading: null,
      }));
      handleLoginFormErrors(error);
    }
  };

  /**
   * Updates the user's password.
   *
   * @returns {Promise<void>} A promise that resolves when the password is updated.
   * @throws {Error} An error if the password update fails.
   */
  const updatePassword: () => Promise<void> = async (): Promise<void> => {
    // Validate the password
    const errorMessage: string | null = validatePassword(
      updatePasswordState.newPassword,
      updatePasswordState.confirmPassword,
    );

    // If the password is invalid, set the error message
    if (errorMessage) {
      setUpdatePasswordState((prevState: UpdatePasswordState) => ({
        ...prevState,
        invalidPasswordErrorMessage: errorMessage,
      }));
    } else if (updatePasswordState.invalidPasswordErrorMessage === null) {
      // If the user is logged in, update the password
      if (updatePasswordState.authToken) {
        setAuthToken(updatePasswordState.authToken);
      // If the user is not logged in, redirect to the login page
      } else {
        navigate('/');
      }

      setUpdatePasswordState((prevState: UpdatePasswordState) => ({
        ...prevState,
        buttonLoading: true,
      }));

      try {
        await requestVantageApi.users({
          method: RequestMethod.PUT,
          path: '/auth/register-password',
          body: { password: updatePasswordState.newPassword },
          handleErrors: true,
        });

        // Show success toast
        showToast({ text: t('view.login.passwordUpdatedToast'), type: ToastType.success });

        setUpdatePasswordState((prevState: UpdatePasswordState) => ({
          ...prevState,
          passwordUpdated: true,
          showPasswordModal: false,
          buttonLoading: null,
        }));

        const user: User | null = getUser();

        // Redirect the user to the correct page
        if (user?.type === UserType.admin) {
          navigate('/');
        } else if (user?.type === UserType.customer) {
          navigate('/plant-management');
        }
      } catch (error: unknown) {
        setUpdatePasswordState((prevState: UpdatePasswordState) => ({
          ...prevState,
          buttonLoading: null,
        }));
        handleLoginFormErrors(error);
      }
    }
  };

  /**
  * Toggles the "Remember Me" checkbox state.
  *
  * This function updates the `rememberMe` property in the `loginFormState` object
  * by setting it to the opposite of its current value.
  */
  const toggleRememberMe = (): void => {
    setLoginFormState((prevState: LoginFormState) => ({
      ...prevState,
      rememberMe: !prevState.rememberMe,
    }));
  };

  /**
  * Updates a specific field in the login form state.
  *
  * @param {string} field - The name of the field to update.
  * @param {string | null | number} [value] - The new value for the field. Defaults to undefined.
  * @returns {void}
  */
  const updateLoginField = (field: keyof LoginFormState, value?: string | null | number): void => {
    setLoginFormState((prevState: LoginFormState) => ({
      ...prevState,
      [field]: value as string | null,
      error: null,
    }));
  };

  /**
 * Updates a specific field in the update password state.
 * As soon as one of the fields is updated, the password is validated
 * and validation errors are shown.
 * @param field - The name of the field to update
 * @param value - The new value for the field
 */
  const updatePasswordField = (
    field: keyof UpdatePasswordState,
    value?: string | null | number,
  ): void => {
    setUpdatePasswordState((prevState: UpdatePasswordState) => {
      const newState = {
        ...prevState,
        [field]: value as string | null,
      };

      if (field === 'newPassword' || field === 'confirmPassword') {
        newState.invalidPasswordErrorMessage = validatePassword(
          newState.newPassword,
          newState.confirmPassword,
        );
      }

      return newState;
    });
  };

  // When close the update password modal reset the state
  const closeUpdatePasswordModal = (): void => {
    setUpdatePasswordState(initialUpdatePasswordState);
  };

  /**
  * Determines if the login form submit button should be disabled.
  * The button is disabled if both the email and password fields are null or undefined.
  * @type {boolean}
  */
  const isDisabledToSubmitLoginForm: boolean = (
    _.isNil(loginFormState.email) || _.isNil(loginFormState.password)
  );

  return (
    <LeftPanelImageRight>
      <LoginContentContainer>
        <FormContentContainer>

          <TitleContainer>
            <Title
              align={TitleAlign.center}
              size={TitleSize.xs}
              weight={TitleWeight.bold}
            >
              {t('view.login.title').split('{trademark}')[0]}
              <RegisteredTrademark>®</RegisteredTrademark>
              {t('view.login.title').split('{trademark}')[1]}
            </Title>
          </TitleContainer>

          <Description>
            <Paragraph align={ParagraphAlign.center}>
              {t('view.login.instructionsParagraph')}
            </Paragraph>
          </Description>

          {loginFormState.error && (
            <AlertContainer>
              <Alert
                closable={false}
                description={loginFormState.error}
                icon={<AlertLine />}
                testId="errorAlert"
                type={AlertType.error}
              />
            </AlertContainer>
          )}

          <InputEmail>
            <Input
              error={loginFormState.error !== null}
              label={`${t('common.email')}`}
              onChange={(value?: string | number | null) => updateLoginField('email', value)}
              testId="input-email"
              value={loginFormState.email}
              onPressEnter={() => {
                const inputs = Array.from(document.querySelectorAll('input'))[1];
                if (inputs) inputs.focus();
              }}
            />
          </InputEmail>

          <Input
            error={loginFormState.error !== null}
            label={t('common.password') || ''}
            onChange={(value?: string | number | null) => updateLoginField('password', value)}
            testId="input-password"
            type={InputType.password}
            value={loginFormState.password}
            onPressEnter={!isDisabledToSubmitLoginForm ? submitLoginForm : undefined}
          />

          <OptionsLoginContainer>
            <Checkbox
              checked={loginFormState.rememberMe}
              onChange={toggleRememberMe}
              size={CheckboxSize.sm}
              text={`${t('view.login.rememberMeCheckbox')}`}
            />
            <LinkTo // @TODO - Add the correct link
              color={LinkToColor.primary}
              target="/"
              type={LinkToType.link}
              weight={LinkToWeight.medium}
            >
              {t('view.login.forgotPasswordLinkTo')}
            </LinkTo>
          </OptionsLoginContainer>

          <Button
            size={ButtonSize.lg}
            loading={updatePasswordState.buttonLoading}
            disabled={isDisabledToSubmitLoginForm}
            loadingText={`${t('view.login.signingIn')}`}
            onClick={submitLoginForm}
            testId="signing-in-button"
          >
            {t('view.login.signIn')}
          </Button>

        </FormContentContainer>
      </LoginContentContainer>
      {updatePasswordState.showPasswordModal && (
        <ModalPanel
          title={`${t('view.login.updatePasswordModalTitle')}`}
          showModal={updatePasswordState.showPasswordModal}
          onClose={closeUpdatePasswordModal}
          size={ModalSize.sm}
        >

          <UpdatePasswordContent>
            <Paragraph>
              {t('view.login.paragraphInfoUpdatePassword')}
            </Paragraph>

            <Input
              label={`${t('view.login.newPasswordField')}`}
              type={InputType.password}
              onChange={(value) => updatePasswordField('newPassword', value)}
              value={updatePasswordState.newPassword}
              error={updatePasswordState.invalidPasswordErrorMessage !== null}
              maxLength={100}
            />
            <Input
              label={`${t('view.login.confirmPasswordField')}`}
              type={InputType.password}
              onChange={(value) => updatePasswordField('confirmPassword', value)}
              value={updatePasswordState.confirmPassword}
              error={updatePasswordState.invalidPasswordErrorMessage !== null}
              maxLength={100}
            />

            {updatePasswordState.invalidPasswordErrorMessage && (
              <UpdatePasswordAlert>
                <Alert
                  icon={<AlertLine />}
                  type={AlertType.error}
                  description={updatePasswordState.invalidPasswordErrorMessage}
                  closable={false}
                />
              </UpdatePasswordAlert>
            )}

            <UpdatePasswordButtonContainer>
              <Button
                onClick={updatePassword}
                loading={updatePasswordState.buttonLoading}
                loadingText={`${t('view.login.updatingPasswordButtonLoading')}`}
                disabled={
                !updatePasswordState.newPassword
                || !updatePasswordState.confirmPassword
                || updatePasswordState.newPassword.length < 8
                || updatePasswordState.confirmPassword.length < 8
              }
              >
                {t('view.login.updatePasswordButton')}
              </Button>
            </UpdatePasswordButtonContainer>
          </UpdatePasswordContent>
        </ModalPanel>
      )}
    </LeftPanelImageRight>
  );
}

export { Login };

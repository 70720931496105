// Core
import React, {
  useEffect,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { formatCrusherModel } from '../../../../../plugins/crusher';
import { capitalizeFirstLetter } from '../../../../../plugins/general';
import { RequestMethod, requestVantageApi } from '../../../../../plugins/request';

// Types
import { CrusherModel, CrusherPLCTagSchema } from '../../../../../types/crusher';
import { EquipmentCategory } from '../../../../../types/global';

// Media
import ImageCrusher from '../../../../../media/images/equipment/crusher.png';
import ImageRoll from '../../../../../media/images/equipment/roll.png';
import ImageScreen from '../../../../../media/images/equipment/screen.png';
import ImageWasher from '../../../../../media/images/equipment/washer.png';

// Components
// import { Button, ButtonType } from '../../../../../layout/elements/Button';
import { CircleInfo } from '../../../../../layout/icons';
import { Drawer } from '../../../../../layout/elements/Drawer';
// import { FileUpload } from '../../../../../layout/elements/FileUpload';
import { Input } from '../../../../../layout/elements/Input';
import { Paragraph, ParagraphWeight } from '../../../../../layout/elements/Paragraph';
import { Select, SelectSizeType } from '../../../../../layout/elements/Select';
import { StepCard } from '../../../../../layout/elements/StepCard';
import { showToast, ToastType } from '../../../../../layout/elements/Toast';
import { Tooltip, TooltipPlacement } from '../../../../../layout/elements/Tooltip';

// View
import { CreateInventoryProps, DrawerCreateOperations } from './Create.types';
import {
  ActionsStepContainer,
  // ButtonContainer,
  CustomerInfoActionsContainer,
  CustomerInfoContainer,
  DescriptionContainer,
  DescriptionContent,
  // FileUploadContainer,
  MainInfoActionsContainer,
  SelectEquipmentListContainer,
} from './Create.style';

function CreateInventory({
  className,
  crusherSchemas,
  customer,
  isDrawerOpen,
  onCloseDrawer,
  onUpdateData,
  selectedEquipmentCategory,
  testId,
}: CreateInventoryProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  /* ***********************************************************************************************
  ***************************************** LOCAL STATES *******************************************
  *********************************************************************************************** */

  interface State {
    dataSchema: string | null;
    isLoading: number | true | null;
    operation: {
      import: boolean;
      main: boolean;
      preRegister: boolean;
    };
    plcTagSchema: string | null;
    selectedCrusherModel: string | null;
    selectedEquipmentCategoryOnDrawer: string | null;
    valueCustomerUnitName?: string | number | null;
    valueSerialNumber?: string | number | null;
  }

  const initialState: State = {
    dataSchema: null,
    isLoading: null,
    operation: {
      import: false,
      main: isDrawerOpen,
      preRegister: false,
    },
    plcTagSchema: null,
    selectedCrusherModel: null,
    selectedEquipmentCategoryOnDrawer: null,
    valueCustomerUnitName: null,
    valueSerialNumber: null,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  ******************************************** METHODS *********************************************
  *********************************************************************************************** */

  const updateDrawerState = (
    updates: Partial<State['operation']>,
    resetCategory: boolean = false,
  ): void => {
    setState((prevState: State): State => {
      const updateState: State = {
        ...prevState,
        operation: {
          main: false,
          preRegister: false,
          import: false,
          ...updates,
        },
        selectedEquipmentCategoryOnDrawer: resetCategory
          ? null
          : prevState.selectedEquipmentCategoryOnDrawer,
      };

      if (resetCategory) {
        onCloseDrawer();
      }

      return updateState;
    });
  };

  const onSelectedEquipmentCategoryOnDrawer = (category: EquipmentCategory): void => {
    setState((prevState: State): State => ({
      ...prevState,
      selectedEquipmentCategoryOnDrawer: category,
    }));
  };

  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: 100,
    }));
  };

  const endpoint = (category: string | null): string => {
    let path: string = '';

    switch (category) {
      case EquipmentCategory.crusher:
        path = '/crusher/inventory';
        break;
      case EquipmentCategory.roll:
        path = '/roll/inventory';
        break;
      case EquipmentCategory.screen:
        path = '/screen/inventory';
        break;
      case EquipmentCategory.washer:
        path = '/washer/inventory';
        break;
      default:
        path = '';
        break;
    }

    return path;
  };

  /* Request */
  const onCreate = async (): Promise<void> => {
    startDrawerLoading();

    try {
      await requestVantageApi.equipmentUnit({
        method: RequestMethod.POST,
        path: endpoint(state.selectedEquipmentCategoryOnDrawer),
        params: { idCustomer: customer.id },
        body: {
          customerUnitName: state.valueCustomerUnitName,
          dataSchema: state.dataSchema,
          model: state.selectedCrusherModel,
          plcTagSchema: state.plcTagSchema,
          serialNumber: state.valueSerialNumber,
        },
      });

      if (state.selectedEquipmentCategoryOnDrawer) {
        showToast({
          text: t('view.inventory.create.createEquipment', {
            category: capitalizeFirstLetter(state.selectedEquipmentCategoryOnDrawer),
          }),
          type: ToastType.success,
        });
      }

      stopDrawerLoading();

      setTimeout((): void => {
        updateDrawerState({ main: false, preRegister: false, import: false }, true);
        onUpdateData();
      }, 300);
    } catch (error: unknown) {
      setState((prevState: State): State => ({
        ...prevState,
        isLoading: null,
      }));
    }
  };

  const handleDrawerToggle = (drawer: DrawerCreateOperations, isOpen: boolean): void => {
    updateDrawerState({ [drawer]: isOpen });
  };

  const closeDrawer = (): void => {
    updateDrawerState({ main: false, preRegister: false, import: false }, true);
    setState((prevState: State): State => ({
      ...prevState,
      dataSchema: null,
      plcTagSchema: null,
      selectedCrusherModel: null,
      valueCustomerUnitName: null,
      valueSerialNumber: null,
    }));
  };

  const isCreateButtonDisabled = (): boolean => (
    !state.selectedCrusherModel
    || !state.valueSerialNumber
    || !state.plcTagSchema
    || !state.dataSchema
  );

  const renderContent = (): JSX.Element | null => {
    switch (true) {
      case state.operation.main:
        return (
          (selectedEquipmentCategory === 'all'
            && state.selectedEquipmentCategoryOnDrawer === null) ? (
              <DescriptionContainer>
                <DescriptionContent>
                  <Paragraph weight={ParagraphWeight.bold}>
                    {`${t('view.inventory.create.description')}`}
                  </Paragraph>
                </DescriptionContent>
                <SelectEquipmentListContainer>
                  <StepCard
                    onClick={() => onSelectedEquipmentCategoryOnDrawer(EquipmentCategory.crusher)}
                    title={`${t('common.equipmentCategory.crusher.singular')}`}
                    imgSrc={ImageCrusher}
                  />
                  <StepCard
                    onClick={(): void => {}} // @TODO add function when back-end ready
                    title={`${t('common.equipmentCategory.roll.singular')}`}
                    imgSrc={ImageRoll}
                  />
                  <StepCard
                    onClick={(): void => {}} // @TODO add function when back-end ready
                    title={`${t('common.equipmentCategory.screen.singular')}`}
                    imgSrc={ImageScreen}
                  />
                  <StepCard
                    onClick={(): void => {}} // @TODO add function when back-end ready
                    title={`${t('common.equipmentCategory.washer.singular')}`}
                    imgSrc={ImageWasher}
                  />
                </SelectEquipmentListContainer>
              </DescriptionContainer>
            ) : (
              <div>
                <ActionsStepContainer>
                  <StepCard
                    testId="inventory-preRegister-step-card"
                    onClick={() => handleDrawerToggle(DrawerCreateOperations.preRegister, true)}
                    title={t('view.inventory.create.addStepCardTitle', {
                      category: t(`common.equipmentCategory.${
                        state.selectedEquipmentCategoryOnDrawer
                      }.singular`),
                    })}
                    description={`${t(
                      'view.inventory.create.addStepCardDescription',
                      {
                        category: t(`common.equipmentCategory.${
                          state.selectedEquipmentCategoryOnDrawer
                        }.singular`),
                      },
                    )}`}
                  />
                  {/* @TODO add stepCard when back-end ready */}
                  {/* <StepCard */}
                  {/*  onClick={() => handleDrawerToggle(DrawerCreateOperations.import, true)} */}
                  {/*  title={t( */}
                  {/*    'view.inventory.create.importStepCardTitle', */}
                  {/*    { */}
                  {/*      category: t(`common.equipmentCategory.${ */}
                  {/*        state.selectedEquipmentCategoryOnDrawer */}
                  {/*      }.plural`), */}
                  {/*    }, */}
                  {/*  )} */}
                  {/*  description={`${t('view.inventory.create.importStepCardDescription')}`} */}
                  {/*  icon={<DownloadLine />} */}
                  {/* /> */}
                </ActionsStepContainer>
              </div>
            )
        );

      case state.operation.preRegister:
        return (
          <>
            <DescriptionContainer>
              <Paragraph weight={ParagraphWeight.bold}>
                {`${t('common.mainInfo')}`}
              </Paragraph>
            </DescriptionContainer>
            <MainInfoActionsContainer>
              {/* Serial Number */}
              <Input
                testId="serial-number-input-create-inventory"
                onChange={(value?: string | number | null): void => {
                  setState((prevState: State): State => ({
                    ...prevState,
                    valueSerialNumber: value,
                  }));
                }}
                label={`${t('common.serialNumber')}`}
                placeholder={`${t('common.serialNumber')}`}
                value={state.valueSerialNumber}
                maxLength={35}
              />
              {/* Model */}
              <Select
                testId="crusher-model-select-create-inventory"
                label={`${t('view.crusher.model')}`}
                placeholder={`${t('view.crusher.model')}`}
                items={Object.keys(crusherSchemas || {}).map((model) => ({
                  id: model,
                  text: formatCrusherModel(model as CrusherModel),
                }))}
                onChange={(value): void => {
                  const selectedValue: string | null = typeof value === 'string'
                    ? value
                    : (value[0] || null);
                  setState((prevState: State) => ({
                    ...prevState,
                    selectedCrusherModel: selectedValue,
                  }));
                }}
                value={state.selectedCrusherModel || undefined}
                size={SelectSizeType.lg}
                allowClear={false}
              />
              {/* PLC Tag Schema */}
              <Select
                testId="plc-tag-schema-select-create-inventory"
                label="PLC Tag Schema"
                placeholder="PLC Tag Schema"
                items={
                  Array.from(
                    new Set(
                      Object.values(crusherSchemas || {})
                        .flatMap((plcSchemas) => Object.keys(plcSchemas)),
                    ),
                  ).map((tag) => ({
                    id: tag,
                    text: tag,
                  }))
                }
                onChange={(value): void => {
                  const selectedValue: string | null = typeof value === 'string'
                    ? value
                    : (value[0] || null);
                  setState((prevState: State) => ({
                    ...prevState,
                    plcTagSchema: selectedValue as CrusherPLCTagSchema,
                  }));
                }}
                value={state.plcTagSchema || undefined}
                size={SelectSizeType.lg}
                allowClear={false}
              />
              {/* Data Schema */}
              <Select
                testId="crusher-data-schema-select-create-inventory"
                label="Data Schema"
                placeholder="Data Schema"
                items={
                  Array.from(
                    new Set(
                      Object.values(crusherSchemas || {})
                        .flatMap((plcTags) => Object.values(plcTags).flat()),
                    ),
                  ).map((dataSchema) => ({
                    id: dataSchema as string,
                    text: dataSchema as string,
                  }))
                }
                onChange={(value): void => {
                  const selectedValue: string | null = typeof value === 'string'
                    ? value
                    : (value[0] || null);
                  setState((prevState: State) => ({
                    ...prevState,
                    dataSchema: selectedValue,
                  }));
                }}
                value={state.dataSchema || undefined}
                size={SelectSizeType.lg}
                allowClear={false}
              />
            </MainInfoActionsContainer>
            <CustomerInfoContainer>
              <Paragraph weight={ParagraphWeight.bold}>
                {`${t('view.inventory.customerInfo')}`}
              </Paragraph>
            </CustomerInfoContainer>
            <CustomerInfoActionsContainer>
              {/* Customer Unit Name */}
              <Input
                testId="customer-unit-name-input-create-inventory"
                onChange={(value?: string | number | null): void => {
                  setState((prevState: State): State => ({
                    ...prevState,
                    valueCustomerUnitName: value === '' ? null : value,
                  }));
                }}
                label={`${t('common.customerUnitName')}`}
                placeholder={`${t('common.customerUnitName')}`}
                value={state.valueCustomerUnitName}
                maxLength={35}
                suffix={(
                  <Tooltip
                    text={`${t('view.inventory.create.tooltip.customerUnitName')}`}
                    placement={TooltipPlacement.left}
                  >
                    <CircleInfo />
                  </Tooltip>
              )}
              />
            </CustomerInfoActionsContainer>
          </>
        );

        // @TODO add drawer when back-end ready
        // case state.operation.import:
        //   return (
        //     <Drawer
        //       title={`${t('view.inventory.create.importTitle')}`}
        //       open={drawerState.import}
        //       onClose={() => handleDrawerToggle(DrawerCreateOperations.import, false)}
        //     >
        //       <DescriptionContainer>
        //         <Paragraph>
        //           {t('view.inventory.create.importDescription')}
        //         </Paragraph>
        //       </DescriptionContainer>
        //
        //       <ButtonContainer>
        //         <Button
        //           onClick={() => {}}
        //           type={ButtonType.secondary}
        //           disabled
        //         >
        //           {t('view.inventory.create.button')}
        //         </Button>
        //       </ButtonContainer>
        //
        //       <Paragraph weight={ParagraphWeight.bold}>
        //         {t('view.inventory.create.fileUploadContainerDescription')}
        //       </Paragraph>
        //
        //       <FileUploadContainer>
        //         <FileUpload
        //           file={{
        //             source: '',
        //             fileName: '',
        //           }}
        //           id="file-upload-inventory"
        //           onRemove={() => {}}
        //           onUpload={() => {}}
        //           supportingText={`${t('view.inventory.create.fileUploadSupportingText')}`}
        //           disabled
        //         />
        //       </FileUploadContainer>
        //     </Drawer>
        //   );

      default:
        return null;
    }
  };

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  *********************************************************************************************** */

  useEffect((): void => {
    setState((prevState: State): State => ({
      ...prevState,
      operation: {
        ...prevState.operation,
        main: isDrawerOpen,
      },
    }));
  }, [isDrawerOpen]);

  useEffect((): void => {
    if (isDrawerOpen) {
      setState((prevState: State): State => ({
        ...prevState,
        selectedEquipmentCategoryOnDrawer: selectedEquipmentCategory === 'all'
          ? null
          : selectedEquipmentCategory,
      }));
    }
  }, [isDrawerOpen, selectedEquipmentCategory]);

  return (
    <Drawer
      testId={testId}
      className={className}
      title={`${t('view.inventory.create.title')}`}
      onClose={() => closeDrawer()}
      open={isDrawerOpen}
      primaryAction={
        state.operation.preRegister
          ? {
            children: `${t('common.create')}`,
            onClick: onCreate,
            disabled: isCreateButtonDisabled(),
            loading: state.isLoading,
            loadingText: `${t('common.creating')}`,
          }
          : undefined
      }
      secondaryAction={
        state.operation.preRegister
          ? { children: `${t('common.cancel')}`, onClick: () => closeDrawer() }
          : undefined
      }
    >
      {renderContent()}
    </Drawer>
  );
}

export { CreateInventory };

// Types
import { CrusherSchemas } from '../../../../../types/crusher';
import { Customer } from '../../../../../types/global';

export enum DrawerCreateOperations {
  main = 'main',
  preRegister = 'preRegister',
  import = 'import',
}

export interface CreateInventoryProps {
  className?: string;
  customer: Customer;
  isDrawerOpen: boolean;
  onCloseDrawer: () => void;
  onUpdateData: () => void;
  selectedEquipmentCategory: string | null;
  crusherSchemas: CrusherSchemas | null;
  testId?: string;
}

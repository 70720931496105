// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Components
import { Paragraph, ParagraphWeight } from '../../../layout/elements/Paragraph';
import { Select, SelectSizeType } from '../../../layout/elements/Select';

// Store
import {
  LengthType,
  MeasurementStore,
  MeasurementType,
  PressureType,
  setMeasurementUnit,
  TemperatureType,
  useMeasurementStore,
} from '../../../store/measurement.store';

// View
import { MeasurementModuleProps } from './MeasurementModule.types';
import {
  ActionsContainer,
  Container,
  Content,
  TitleContainer,
} from './MeasurementModule.style';
import { showToast, ToastType } from '../../../layout/elements/Toast';

function MeasurementModule({
  className,
  testId,
}: MeasurementModuleProps): JSX.Element | null {
  // Dependencies
  const { t } = useTranslation();

  const selectedTemperature: TemperatureType = useMeasurementStore((
    state: MeasurementStore,
  ) => state.temperature);

  const selectedPressure: PressureType = useMeasurementStore((
    state: MeasurementStore,
  ) => state.pressure);

  const selectedLength: LengthType = useMeasurementStore((
    state: MeasurementStore,
  ) => state.length);

  /* Handles the change of the measurement unit */
  const handleUnitChange = (
    value: TemperatureType | PressureType | LengthType,
    measurementUnit: MeasurementType,
  ): void => {
    setMeasurementUnit(value, measurementUnit);

    showToast({
      text: t('view.settings.measurementModule.toastDescription'),
      type: ToastType.success,
    });
  };

  return (
    <div
      className={className}
      data-testid={testId}
    >
      <Container>
        <Content>
          <TitleContainer>
            <Paragraph
              weight={ParagraphWeight.bold}
            >
              {t('view.settings.measurementModule.title')}
            </Paragraph>
          </TitleContainer>
          <ActionsContainer>
            <Select
              testId="temperature-select-measurement-module"
              label={t('common.temperature')}
              placeholder={t('common.temperature')}
              size={SelectSizeType.lg}
              items={[
                { text: 'Celsius', id: TemperatureType.c },
                { text: 'Fahrenheit', id: TemperatureType.f },
              ]}
              value={selectedTemperature}
              onChange={(value): void => handleUnitChange(
                value as TemperatureType,
                MeasurementType.temperature,
              )}
              allowClear={false}
            />
            <Select
              testId="length-select-measurement-module"
              label={t('common.length')}
              placeholder={t('common.length')}
              size={SelectSizeType.lg}
              items={[
                { text: t('common.measurement.millimeter'), id: LengthType.mm },
                { text: t('common.measurement.inch'), id: LengthType.inch },
              ]}
              value={selectedLength}
              onChange={(value): void => handleUnitChange(
                value as LengthType,
                MeasurementType.length,
              )}
              allowClear={false}
            />
            <Select
              testId="pressure-select-measurement-module"
              label={t('common.pressure')}
              placeholder={t('common.pressure')}
              size={SelectSizeType.lg}
              items={[
                { text: t('common.measurement.megaPascal'), id: PressureType.mpa },
                { text: 'Bar', id: PressureType.bar },
                { text: 'Psi'.toUpperCase(), id: PressureType.psi },
              ]}
              value={selectedPressure}
              onChange={(value): void => handleUnitChange(
                value as PressureType,
                MeasurementType.pressure,
              )}
              allowClear={false}
            />
          </ActionsContainer>
        </Content>
      </Container>
    </div>
  );
}

export { MeasurementModule };

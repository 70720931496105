// Types
import { CrusherModel, EquipmentUnitCrusher } from '../../../types/crusher';

export enum DrawerOperation {
  create = 'create',
  delete = 'delete',
  read = 'read',
  update = 'update',
}

export enum ColumnId {
  checkbox = 'checkbox',
  createdAt = 'createdAt',
  customer = 'customer',
  customerUnitName = 'customerUnitName',
  dropdown = 'dropdown',
  equipmentCategory = 'equipmentCategory',
  installStatus = 'installStatus',
  serialNumber = 'serialNumber',
  superiorPartNumber = 'superiorPartNumber',
}

export interface InfoEquipmentCategory {
  imgSrc?: string;
  key: string;
  count: number;
  title: string;
  id: string;
}

export interface CombinedEquipmentsAndCustomer extends EquipmentUnitCrusher {
  customer: string | null;
  key?: string;
  model: CrusherModel;
}

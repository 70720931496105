// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

// Plugins
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  overallStatusToTagType,
  overallStatusToText,
  permissiveStatusToIndicatorStatus,
  runningEnabledStatusToIndicatorStatus,
  runningStatusToIndicatorStatus,
  weatherModeStatusToTagType,
  weatherModeStatusToText,
} from '../../../../../../plugins/crusher';
import { convertPressure, convertTemperature } from '../../../../../../plugins/general';

// Store
import {
  getMeasurementUnit,
  MeasurementType,
  PressureType,
  TemperatureType,
} from '../../../../../../store/measurement.store';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../../types/global';
import { Theme } from '../../../../../../types/theme';

// Components
import { AlertLine } from '../../../../../../layout/icons';
import { AlertType } from '../../../../../../layout/elements/Alert';
import {
  Button,
  ButtonSize,
  ButtonType,
} from '../../../../../../layout/elements/Button';
import { Col } from '../../../../../../layout/elements/Col';
import { Drawer, DrawerSize } from '../../../../../../layout/elements/Drawer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../../layout/elements/Row';
import { Speedometer } from '../../../../../../layout/elements/Speedometer';
import { StatusIndicator, StatusIndicatorSize } from '../../../../../../layout/elements/StatusIndicator';
import { Tag } from '../../../../../../layout/elements/Tag';
import { Thermometer } from '../../../../../../layout/elements/Thermometer';

// Component
import { LubricationUnitProps } from './LubricationUnit.types';
import {
  BorderBottomContainer,
  HeaterPermissiveListContainer,
  LubePressureContainer,
  LubricationContainer,
  OverallStatusContainer,
  PermissiveItem,
  PermissiveListContainer,
  PermissiveTitleContainer,
  RunningStatusContainer,
  RunningStatusItem,
  RunningStatusList,
  ThermometerContainer,
  WinterModeContainer,
  WinterModeStatus,
} from './LubricationUnit.style';

function LubricationUnit({
  className,
  isDrawerOpen,
  onCloseDrawer,
  operationalData,
  testId,
}: LubricationUnitProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const { lubricationUnitDrawer } = theme.color.crusherDashboard;

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);
  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  const userPressureUnit: PressureType = getMeasurementUnit(MeasurementType.pressure);
  const userTemperatureUnit: TemperatureType = getMeasurementUnit(
    MeasurementType.temperature,
  );

  const renderLubePermissive = (
    permissiveName: string,
    permissive: boolean | null | undefined,
  ): JSX.Element => (
    <PermissiveItem
      status={permissiveStatusToIndicatorStatus(
        permissive,
        lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
      )}
    >
      <StatusIndicator
        text={t(`view.crusher.lubrication.drawer.${permissiveName}`)}
        status={permissiveStatusToIndicatorStatus(
          permissive,
          lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
        )}
      />
    </PermissiveItem>
  );

  const renderHeaterPermissive = (
    permissiveName: string,
    permissive: boolean | null | undefined,
  ): JSX.Element => (
    <PermissiveItem
      status={permissiveStatusToIndicatorStatus(
        permissive,
        lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
      )}
    >
      <StatusIndicator
        text={t(`view.crusher.lubrication.drawer.${permissiveName}`)}
        status={permissiveStatusToIndicatorStatus(
          permissive,
          lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
        )}
      />
    </PermissiveItem>
  );

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t('view.crusher.lubrication.drawer.lubricationUnit')}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      footer={false}
      size={DrawerSize.lg}
      alert={lastEdgeStatus?.edgeStatus !== EdgeDeviceOperationalStatusStatus.on && {
        type: AlertType.warning,
        icon: <AlertLine />,
        description: `${t('layout.elements.drawer.alertText')}`,
      }}
    >
      <LubricationContainer>
        <BorderBottomContainer>
          <Row>
            <Col>
              <OverallStatusContainer>
                <Paragraph
                  color={lubricationUnitDrawer.overallStatusDescriptionTextColor}
                  weight={ParagraphWeight.bold}
                >
                  {t('view.crusher.overallStatus')}
                </Paragraph>
                <Tag
                  text={overallStatusToText(lastUnitData?.lubricationOverallStatus)}
                  type={
                    overallStatusToTagType(
                      lastUnitData?.lubricationOverallStatus,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </OverallStatusContainer>
            </Col>
          </Row>
        </BorderBottomContainer>
        <BorderBottomContainer>
          <Row>
            <Col cols={12}>
              <RunningStatusContainer>
                <Paragraph weight={ParagraphWeight.bold}>
                  {t('view.crusher.lubrication.drawer.runningStatus')}
                </Paragraph>
                <RunningStatusList>
                  <RunningStatusItem>
                    <StatusIndicator
                      text={t('view.crusher.lubrication.drawer.lubePump')}
                      status={
                        runningStatusToIndicatorStatus(
                          lastUnitData?.lubePumpRunningStatus,
                          lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                        )
                      }
                    />
                  </RunningStatusItem>
                  <RunningStatusItem>
                    <StatusIndicator
                      text={t('view.crusher.lubrication.drawer.lubeCooler')}
                      status={
                        runningStatusToIndicatorStatus(
                          lastUnitData?.coolerFanStatus,
                          lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                        )
                      }
                    />
                  </RunningStatusItem>
                  <RunningStatusItem>
                    <StatusIndicator
                      text={t('view.crusher.lubrication.drawer.tankHeater')}
                      status={
                        runningEnabledStatusToIndicatorStatus(
                          lastUnitData?.tankLubeHeaterStatus,
                          lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                        )
                      }
                    />
                  </RunningStatusItem>
                </RunningStatusList>
              </RunningStatusContainer>
            </Col>
            <Col cols={12}>
              <LubePressureContainer>
                <Speedometer
                  title={`${t('view.crusher.lubrication.drawer.lubePressure')}`}
                  unit={userPressureUnit}
                  running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  minValue={
                    convertPressure(lastUnitData?.spSupplyPressureScaleMin, userPressureUnit)
                  }
                  maxValue={convertPressure(
                    lastUnitData?.spSupplyPressureScaleMax,
                    userPressureUnit,
                  )}
                  value={convertPressure(
                    lastUnitData?.lubricationOilPressure,
                    userPressureUnit,
                  )}
                  thresholds={{
                    lowAlarm: convertPressure(
                      lastUnitData?.spLubePressureLowAlarm,
                      userPressureUnit,
                    ),
                    lowWarning: convertPressure(
                      lastUnitData?.spLubePressureLowWarning,
                      userPressureUnit,
                    ),
                  }}
                />
              </LubePressureContainer>
            </Col>
          </Row>
        </BorderBottomContainer>
        <BorderBottomContainer>
          <Row>
            <Col>
              <WinterModeContainer>
                <WinterModeStatus>
                  <Paragraph
                    size={ParagraphSize.sm}
                    weight={ParagraphWeight.bold}
                    color={lubricationUnitDrawer.winterModeTextColor}
                  >
                    {t('view.crusher.lubrication.drawer.winterMode')}
                  </Paragraph>
                  <Tag
                    text={weatherModeStatusToText(lastUnitData?.weatherModeStatus)}
                    type={
                      weatherModeStatusToTagType(
                        lastUnitData?.weatherModeStatus,
                        lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                      )
                    }
                  />
                </WinterModeStatus>
                <Button
                  disabled
                  type={ButtonType.secondary}
                  size={ButtonSize.xs}
                  block={false}
                  onClick={() => {}}
                >
                  {t('view.crusher.lubrication.drawer.accessWinterMode')}
                </Button>
              </WinterModeContainer>
            </Col>
          </Row>
        </BorderBottomContainer>
        <BorderBottomContainer>
          <Row>
            <ThermometerContainer>
              <Thermometer
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                thresholds={{
                  lowAlarm: convertTemperature(
                    lastUnitData?.spLubeReturnTempLowAlarm,
                    userTemperatureUnit,
                  ),
                  lowWarning: convertTemperature(
                    lastUnitData?.spLubeReturnTempLowWarning,
                    userTemperatureUnit,
                  ),
                  highWarning: convertTemperature(
                    lastUnitData?.spLubeReturnTempHighWarn,
                    userTemperatureUnit,
                  ),
                }}
                maxValue={convertTemperature(
                  lastUnitData?.spReturnTempScaleMax,
                  userTemperatureUnit,
                )}
                minValue={convertTemperature(
                  lastUnitData?.spReturnTempScaleMin,
                  userTemperatureUnit,
                )}
                value={convertTemperature(
                  lastUnitData?.lubricationReturnTemperature,
                  userTemperatureUnit,
                )}
                unit={userTemperatureUnit}
                title={`${t('view.crusher.lubrication.drawer.returnTemp')}`}
              />
            </ThermometerContainer>
            <ThermometerContainer>
              <Thermometer
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                thresholds={{
                  lowAlarm: convertTemperature(
                    lastUnitData?.spLubeTankTempLowAlarm,
                    userTemperatureUnit,
                  ),
                  lowWarning: convertTemperature(
                    lastUnitData?.spLubeTankTempLowWarning,
                    userTemperatureUnit,
                  ),
                  highWarning: convertTemperature(
                    lastUnitData?.spLubeTankTempHighWarning,
                    userTemperatureUnit,
                  ),
                  highAlarm: convertTemperature(
                    lastUnitData?.spLubeTankTempHighAlarm,
                    userTemperatureUnit,
                  ),
                }}
                maxValue={convertTemperature(
                  lastUnitData?.spTankTempScaleMax,
                  userTemperatureUnit,
                )}
                minValue={convertTemperature(
                  lastUnitData?.spTankTempScaleMin,
                  userTemperatureUnit,
                )}
                value={convertTemperature(
                  lastUnitData?.lubricationTankTemperature,
                  userTemperatureUnit,
                )}
                unit={userTemperatureUnit}
                title={`${t('view.crusher.lubrication.drawer.tankTemp')}`}
              />
            </ThermometerContainer>
            <ThermometerContainer>
              <Thermometer
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                thresholds={{
                  lowWarning: convertTemperature(
                    lastUnitData?.spLubeSupplyTempLowWarn,
                    userTemperatureUnit,
                  ),
                }}
                minValue={convertTemperature(
                  lastUnitData?.spSupplyTempScaleMin,
                  userTemperatureUnit,
                )}
                maxValue={convertTemperature(
                  lastUnitData?.spSupplyTempScaleMax,
                  userTemperatureUnit,
                )}
                value={convertTemperature(
                  lastUnitData?.lubricationSupplyTemperature,
                  userTemperatureUnit,
                )}
                unit={userTemperatureUnit}
                title={`${t('view.crusher.lubrication.drawer.supplyTemp')}`}
              />
            </ThermometerContainer>
            <ThermometerContainer>
              <Thermometer
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                maxValue={convertTemperature(50, userTemperatureUnit, true)}
                minValue={convertTemperature(-50, userTemperatureUnit, true)}
                value={convertTemperature(
                  lastUnitData?.lubeSupplyDifferential,
                  userTemperatureUnit,
                  true,
                )}
                unit={userTemperatureUnit}
                title={`${t('view.crusher.lubrication.drawer.lubeSupplyDifferential')}`}
              />
            </ThermometerContainer>
          </Row>
        </BorderBottomContainer>
        <Row>
          <Col cols={12}>
            <PermissiveListContainer>
              <PermissiveTitleContainer>
                <StatusIndicator
                  text={t('view.crusher.lubrication.drawer.lubePermissive')}
                  status={
                    permissiveStatusToIndicatorStatus(
                      lastUnitData?.lubePermissive,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                  size={StatusIndicatorSize.lg}
                />
              </PermissiveTitleContainer>
              {renderLubePermissive('lubePump1StarterFault', lastUnitData?.lubePump1StarterFault)}
              {renderLubePermissive('lubeOilPressure', lastUnitData?.lubeOilPressure)}
              {renderLubePermissive('heaterEnabled', lastUnitData?.lubeTankHeaterEnable)}
              {renderLubePermissive('lubeTankOilLevel', lastUnitData?.lubeTankOilLevel)}
              {renderLubePermissive('tankTempTooLowToRun', lastUnitData?.tankTempTooLowToRun)}
              {renderLubePermissive('tankTempTooLowToStart', lastUnitData?.tankTempTooLowToStart)}
            </PermissiveListContainer>
          </Col>
          <Col cols={12}>
            <HeaterPermissiveListContainer>
              <PermissiveTitleContainer>
                <StatusIndicator
                  text={t('view.crusher.lubrication.drawer.lubeTankHeater')}
                  status={
                    permissiveStatusToIndicatorStatus(
                      lastUnitData?.lubeHeaterPermissive,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                  size={StatusIndicatorSize.lg}
                />
              </PermissiveTitleContainer>
              {renderHeaterPermissive(
                'lubeHeaterStarterFault',
                lastUnitData?.lubeHeaterStarterFault,
              )}
              {renderHeaterPermissive('lubeTankLevel', lastUnitData?.lubeTankLevel)}
              {renderHeaterPermissive(
                'lubeTankTempTransducerFault',
                lastUnitData?.lubeTankTempTransducerFault,
              )}
            </HeaterPermissiveListContainer>
          </Col>
        </Row>
      </LubricationContainer>
    </Drawer>
  );
}

export { LubricationUnit };

// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  overallStatusToTagType,
  overallStatusToText,
} from '../../../../../plugins/crusher';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';
import { Theme } from '../../../../../types/theme';

// Components
import { Col } from '../../../../../layout/elements/Col';
import { ModuleHeader } from '../../../../../layout/elements/ModuleHeader';
import { Row } from '../../../../../layout/elements/Row';
import { Speedometer } from '../../../../../layout/elements/Speedometer';
import { Tag } from '../../../../../layout/elements/Tag';

// View
import {
  MotorAmpsContainer,
  MotorRpmContainer,
  MotorStatusContainer,
  OverallStatusContainer,
  SpeedometersContainer,
} from './MotorStatusModule.style';
import { CrusherMotorStatusModuleProps } from './MotorStatusModule.types';

function MotorStatusModule({
  className,
  onOpenDrawer,
  operationalData,
  testId,
}: CrusherMotorStatusModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  return (
    <MotorStatusContainer
      data-testid={testId}
      className={className}
    >
      <Row>
        <Col>
          <ModuleHeader
            onClick={onOpenDrawer}
            title={t('view.crusher.crusherMotor.title')}
          />
        </Col>
      </Row>
      <Row>
        <Col cols={24}>
          <OverallStatusContainer>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.medium}
              color={theme.color.crusherDashboard.statusTitleColor}
            >
              {t('view.crusher.overallStatus')}
            </Paragraph>
            <Tag
              text={overallStatusToText(lastUnitData?.crusherMotorOverallStatus)}
              type={overallStatusToTagType(
                lastUnitData?.crusherMotorOverallStatus,
                lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
              )}
            />
          </OverallStatusContainer>
        </Col>
      </Row>
      <SpeedometersContainer>
        <MotorRpmContainer>
          <Speedometer
            className="speedometer"
            minValue={lastUnitData?.spCrusherMotorRpmScaleMin}
            maxValue={lastUnitData?.spCrusherMotorRpmScaleMax}
            value={lastUnitData?.crusherMotorRpm}
            unit="rpm"
            thresholds={{}}
            title={`${t('view.crusher.crusherMotor.motorRmp')}`}
            running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
          />
        </MotorRpmContainer>

        <MotorAmpsContainer>
          <Speedometer
            className="speedometer"
            minValue={lastUnitData?.spCrusherMotorAmpsScaleMin}
            maxValue={lastUnitData?.spCrusherMotorAmpsScaleMax}
            value={lastUnitData?.crusherMotorAmps}
            unit="A"
            thresholds={{
              highWarning: lastUnitData?.spCrusherMotorCrushingAmps,
              highAlarm: lastUnitData?.spCrusherMotorFullLoadAmps,
              lowWarning: lastUnitData?.spCrusherMotorRunningAmps,
            }}
            title={`${t('view.crusher.crusherMotor.motorAmps')}`}
            running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
          />
        </MotorAmpsContainer>
      </SpeedometersContainer>
    </MotorStatusContainer>
  );
}

export { MotorStatusModule };

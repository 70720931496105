// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Store
import { getUser } from '../../../store/session.store';

// Types
import { User } from '../../../types/global';

// Components
import { Col } from '../../../layout/elements/Col';
import {
  SelectedItem,
  SideNavigation,
  SideNavigationProps,
  TypeSideNavigation,
} from '../../../layout/SideNavigation';

// Module
import { SettingsModuleProps } from './SettingsModule.types';

function SettingsModule({
  className,
  onItemSelected,
  selectedItem,
  testId,
}: SettingsModuleProps): JSX.Element | null {
  // Dependencies
  const { t } = useTranslation();

  // Getter
  const user: User | null = getUser();

  if (!user) {
    return null;
  }

  const items: Pick<SideNavigationProps, 'items'>['items'] = [
    {
      key: 'accountSettings',
      label: `${t('view.settings.settingsModule.accountSectionTitle').toUpperCase()}`,
      type: TypeSideNavigation.group,
      children: [
        {
          key: 'profileSettings',
          label: `${t('view.settings.settingsModule.menuItemProfile')}`,
        },
      ],
    },
  ];

  return (
    <Col
      cols={4}
      className={className}
      testId={testId}
    >
      <SideNavigation
        title={`${t('common.settings')}`}
        items={items}
        selectedItem={selectedItem}
        onClick={(item: SelectedItem): void => {
          onItemSelected(item.key);
        }}
      />
    </Col>
  );
}

export { SettingsModule };

// Libraries
import styled from 'styled-components';

// Components
import { StatusIndicatorProps } from '../../../../../../layout/elements/StatusIndicator';

export const FeederInterlockContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.crusherDashboard.feederInterlock.borderColor};
`;

export const FeederStartContainer = styled.div`
  padding: 24px 19px;
  border-bottom: 1px solid ${({
    theme,
  }) => theme.color.crusherDashboard.feederInterlock.borderColor};
  width: 100%;
`;

export const CrusherStartListDescription = styled.div<Pick<StatusIndicatorProps, 'status'>>`
  padding: 12px 20px;
  gap: 2px;
  width: 100%;
  background-color: ${({ status, theme }) => (
    status === 'alarm'
      ? theme.color.crusherDashboard.feederInterlock.descriptionListErrorBackgroundColor
      : 'transparent'
  )};
  border-radius: ${({ status }) => (
    status === 'alarm'
      ? '4px'
      : 'none'
  )};
`;

export const GeneralContainer = styled.div`
  padding: 20px 20px 0 20px;
  border-right: 1px solid ${({
    theme,
  }) => theme.color.crusherDashboard.feederInterlock.borderColor};
`;

export const GeneralPermissives = styled.div`
  padding: 0 20px 20px 20px;
  border-right: 1px solid ${({
    theme,
  }) => theme.color.crusherDashboard.feederInterlock.borderColor};
  border-bottom: 1px solid ${({
    theme,
  }) => theme.color.crusherDashboard.feederInterlock.borderColor};
`;

export const CrusherContainer = styled.div`
  padding: 20px 20px 0 20px;
  border-right: 1px solid ${({
    theme,
  }) => theme.color.crusherDashboard.feederInterlock.borderColor};
`;

export const CrusherPermissives = styled.div`
  padding: 20px;
  border-right: 1px solid ${({
    theme,
  }) => theme.color.crusherDashboard.feederInterlock.borderColor};
`;

export const HydraulicsContainer = styled.div`
  padding: 20px 20px 0 20px;
`;

export const HydraulicsPermissives = styled.div`
  padding: 0 20px 20px 20px;
`;

export const LubricationContainer = styled.div`
  padding: 20px;
  border-top: 1px solid ${({
    theme,
  }) => theme.color.crusherDashboard.feederInterlock.borderColor};
`;

export const LubricationPermissives = styled.div`
  padding: 0 20px 20px 20px;
`;

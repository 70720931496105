// Core
import styled from 'styled-components';

export const LoginContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const OptionsLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 14px;
`;

export const FormContentContainer = styled.div`
  margin: auto;
`;

/* ************** REGISTERED TRADEMARK ******************* */

export const RegisteredTrademark = styled.sup`
  font-size: 13px;
  padding-right: 2px;
  margin-left: -2px;
`;

/* ************** PADDING BETWEEN CONTENTS ******************* */

export const Description = styled.div`
  padding: 19px 0 27px 0;
`;

export const AlertContainer = styled.div`
  padding-bottom: 8px;
`;

export const InputEmail = styled.div`
  padding-bottom: 8px;
`;

export const TitleContainer = styled.div`
  padding-top: 13px;
`;

/* **************** TEMPORARY PASSWORD ******************* */

export const UpdatePasswordAlert = styled.div`
  > div {
    white-space: pre-line;
  }
`;

export const UpdatePasswordContent = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const UpdatePasswordButtonContainer = styled.div`
  padding-bottom: 10px;
`;

// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import { lastOperationalUnitData } from '../../../../../plugins/crusher';
import { convertLength, formatNumber } from '../../../../../plugins/general';

// Store
import {
  getMeasurementUnit,
  LengthType,
  MeasurementType,
} from '../../../../../store/measurement.store';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import { Theme } from '../../../../../types/theme';

// Components
import { Paragraph, ParagraphWeight } from '../../../../../layout/elements/Paragraph';
import {
  Title,
  TitleSize,
  TitleWeight,
} from '../../../../../layout/elements/Title';

// View
import {
  CSSettingsContainer,
  CSSettingsContent,
  ParagraphUnitContainer,
} from './CSSettingsModule.style';
import { CrusherCSSettingsModuleProps } from './CSSettingsModule.types';

function CSSettingsModule({
  className,
  operationalData,
  testId,
}: CrusherCSSettingsModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const userLengthUnit: LengthType = getMeasurementUnit(MeasurementType.length);

  return (
    <CSSettingsContainer data-testid={testId} className={className}>
      <Paragraph weight={ParagraphWeight.bold}>
        {t('view.crusher.csSetting')}
      </Paragraph>
      <CSSettingsContent>
        {/* @TODO transform into a component if necessary to reuse */}
        <Title size={TitleSize.xxs} weight={TitleWeight.medium}>
          {formatNumber(convertLength(lastUnitData?.csSetting, userLengthUnit), 2)}
        </Title>
        {
          userLengthUnit === LengthType.inch
            ? (
              <Title
                size={TitleSize.xxs}
                weight={TitleWeight.bold}
                color={theme.color.crusherDashboard.csSettings.unitTextColor}
              >
                {userLengthUnit}
              </Title>
            ) : (
              <ParagraphUnitContainer>
                <Paragraph
                  weight={ParagraphWeight.bold}
                  color={theme.color.crusherDashboard.csSettings.unitTextColor}
                >
                  {userLengthUnit}
                </Paragraph>
              </ParagraphUnitContainer>
            )
          }
      </CSSettingsContent>
    </CSSettingsContainer>
  );
}

export { CSSettingsModule };

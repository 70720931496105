// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../../types/crusher';
import { EdgeDeviceOperationalStatus } from '../../../../../../types/global';

// Store
import { MeasurementType } from '../../../../../../store/measurement.store';

// Components
import {
  LineChart,
  LineChartType,
  Series,
} from '../../../../../../layout/elements/LineChart';

// View
import { TrendingsLubeUnitProps } from './TrendingsLubeUnit.types';

function TrendingsLubeUnit({
  className,
  operationalData,
  testId,
}: TrendingsLubeUnitProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const series: Series[] = [
    {
      name: t('common.cloudConnection'),
      field: 'edgeStatus',
      type: LineChartType.fill,
      date: 'timestamp',
      opposite: true,
      measurementType: MeasurementType.temperature,
    },
    {
      name: t('view.crusher.trendings.lubeUnit.legend.supplyTemp'),
      field: 'lubricationSupplyTemperature',
      type: LineChartType.line,
      date: 'timestamp',
      unit: '°C',
      decimals: 2,
      measurementType: MeasurementType.temperature,
      bullet: true,
    },
    {
      name: t('view.crusher.trendings.lubeUnit.legend.returnTemp'),
      field: 'lubricationReturnTemperature',
      type: LineChartType.line,
      date: 'timestamp',
      unit: '°C',
      decimals: 2,
      bullet: true,
      measurementType: MeasurementType.temperature,
    },
    {
      name: t('view.crusher.trendings.lubeUnit.legend.tankTemp'),
      field: 'lubricationTankTemperature',
      type: LineChartType.line,
      date: 'timestamp',
      unit: '°C',
      decimals: 2,
      bullet: true,
      measurementType: MeasurementType.temperature,
    },
    {
      name: t('view.crusher.trendings.lubeUnit.legend.lubePressure'),
      field: 'lubricationOilPressure',
      type: LineChartType.line,
      date: 'timestamp',
      unit: 'psi',
      opposite: true,
      decimals: 2,
      bullet: true,
      measurementType: MeasurementType.pressure,
    },
  ];

  const convertedEdgeStatus: (
    EquipmentUnitDataCrusher | { edgeStatus: number, timestamp: number }
  )[] = operationalData.map((item: EquipmentUnitDataCrusher | EdgeDeviceOperationalStatus) => (
    'edgeStatus' in item
      ? { ...item, edgeStatus: item.edgeStatus === 'on' ? 1 : 0 }
      : item));

  return (
    <LineChart
      activeBullet
      className={className}
      data={convertedEdgeStatus}
      id="lube-unit-chart"
      series={series}
      testId={testId}
      timeUnit="second"
    />
  );
}

export { TrendingsLubeUnit };
